// src/components/WebSocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from "socket.io-client";

const WebSocketContext = createContext(null);
const apiUrl = process.env.REACT_APP_API_URL;
export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [stockUpdates, setStockUpdates] = useState([]);
  const [partUpdates, setPartUpdates] = useState([])

  useEffect(() => {
    console.log("trying to connect to websocket server")
    const socket = io(apiUrl, {
      transports: ["websocket", "polling"], // Explicitly set the transports
      reconnectionAttempts: 3, // Limit the number of reconnection attempts
      timeout: 20000, // Set a timeout for the connection attempt
      debug: true
    })
    
    

    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });

    socket.on('connect_timeout', () => {
        console.error('Connection timed out');
    });

    socket.on('reconnect_attempt', (attempt) => {
        console.log(`Reconnect attempt ${attempt}`);
    });

    socket.on('reconnect_failed', () => {
        console.error('Reconnection failed');
    });

    socket.on('error', (error) => {
        console.error('Socket error:', error);
    });

    socket.on('stock_update', (data) => {
      setStockUpdates((prevUpdates) => [...prevUpdates, data]);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    socket.on('part_update', (data) => {
      setPartUpdates((prevUpdates) => [...prevUpdates, data]);
    });

    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket, stockUpdates, setStockUpdates, partUpdates, setPartUpdates }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
