/* global google */
import React, {useState, useEffect} from 'react'
import { isValidPhoneNumber } from 'libphonenumber-js' //put this instead on the profile page to check phone numbers
import './css/customerInformationComponent.css'
import '@stripe/stripe-js';
import { useAuth } from '../AuthContext.js';
import { useFormData } from './context/FormDataContext.js'
import { useGoogleMaps } from './hook/useGoogleMaps.js'
import InputBoxComponent from '../InputBoxComponent.tsx';

function CustomerInformationComponent() { 
    const {user, setUser} = useAuth()
    
    return(
        <div className="customer-information" style={{ "width": "100%", "gap":"10px"}}>
            <div className ='contact-details' style={{"width": "100%"}}>
                <h2>Contact</h2>
                <div className="form-group">
                    <div className="location-container" style={{"display":"flex","minWidth":"100%","gap":"10px"}}>
                        <div style={{width: "100%"}}>
                            <InputBoxComponent
                              string={user.email}
                              setString={''}
                              label={'Email Address'}
                              regexType='string'
                              defaultValue={user.email}
                              disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    )
}

export default CustomerInformationComponent