import React, { useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { useAuth } from './../AuthContext.js';
import outlookLogo from './../../assets/ms-symbollockup_mssymbol_19.svg'
import { useTargetRoute } from './../TargetRoute';
import  { AlertContext } from './../body/customerBody/AlertContext'
const apiUrl = process.env.REACT_APP_API_URL;

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: process.env.REACT_APP_REDIRECT_URL
    }
};

const loginRequest = {
    scopes: ['User.Read']
};

function LoginButton() {
    const { setUser } = useAuth();
    const navigate = useNavigate();
    const msalInstance = useMemo(() => new PublicClientApplication(msalConfig), []);
    const { showAlertMessage } = useContext(AlertContext);

    const handleLogin = async () => {
        try {
            await msalInstance.initialize();
            const response = await msalInstance.loginPopup(loginRequest);
            if (response) {
                const csrfResponse = await fetch(`${apiUrl}/api/csrf-token`, {
                    credentials: 'include',
                })
                const csrfData = await csrfResponse.json();
                const csrfToken = csrfData.csrf_token;

                const { account, idToken, idTokenClaims } = response;
                setUser({
                    given_name: account.idTokenClaims.name,
                    email: account.username,
                    userType: null,
                    company: null,
                });

                const payload={
                    email: account.username,
                    idToken,
                    idTokenClaims
                }

                const outlookValidationResponse = await fetch(`${apiUrl}/api/outlook-signin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken,
                    },
                    body: JSON.stringify(payload),
                    credentials: 'include',
                });
                const data = await outlookValidationResponse.json();
                if (data.success) {
                    fetch(`${apiUrl}/api/userValidation`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          'X-CSRFToken': csrfToken,
                        },
                        body: JSON.stringify(payload),
                        credentials: 'include',
                      })
                      .then(response => response.json())
                      .then(data => {
                        if (data.success) {
                            setUser(prevUser => {
                                const updatedUser = {
                                    ...prevUser,
                                    userType: data.user_type,
                                    email: data.email,
                                    company: data.company,
                                    given_name: data.given_name,
                                    family_name: data.family_name,
                                    contact_number: data.contact_number,
                                    abn: data.abn,
                                    accounts_email: data.accounts_email,
                                    previous_delivery: data.previous_delivery,
                                    previous_billing: data.previous_billing
                                };
                                //localStorage.setItem('user', JSON.stringify(updatedUser)); // Update local storage
                                return updatedUser;
                            });
                            navigate('/customer');
                        } else {
                            //redirect to signup
                            setUser(prevUser => ({
                                ...prevUser,
                                email: account.username,
                                userType: 'sign up'
                            }));
                        }
                    });
                } else {
                    showAlertMessage('warning','You are not authorized to view this page')
                    //console.error('authentication failed',data.error)
                }
                
                
            }
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    return (
        <button onClick={handleLogin} className="outlook-login-button">
            <img src={outlookLogo} style={{width: '18px', height: '18px', marginRight: '8px'}}></img>
            <span style={{width: '100%', textAlign: 'left'}}>Continue with Microsoft</span>
            
        </button>
    );
}

export default LoginButton;


