import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormData } from './FormDataContext.js'

import { AlertContext } from "./../../body/customerBody/AlertContext"
import { useShipmentData } from './ShipmentDataContext.js'

const BreadCrumbContext = createContext();

function BreadCrumbProvider({ children }) {
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(1);
    const [orderNumber, setOrderNumber] = useState('')

    const { showAlertMessage } = useContext(AlertContext);
    const { formData, formData3 } = useFormData();
    const { shippingData } = useShipmentData()

    const abnRegex = /^[0-9]{11}$/;
    const contactNumberRegex = /^04[0-9]{8}$/;
    const postcodeRegex = /^[0-9]{4}$/;
    const stateRegex = /^(nsw|vic|qld|wa|sa|tas|act|nt)$/
    const countryRegex = /^(australia)$/
    const stringRegex = /^[A-Za-z]+$/;
    const companyRegex = /^[A-Za-z0-9\s\-.,&']+$/;
    const localityRegex = /^[A-Za-z0-9\s]+$/;
    const suiteRegex = /^[A-Za-z0-9\s]+$/;

    const validateFormData = () => {
        const { abn, contact_number, postal_code, administrative_area_level_1, country, given_name, family_name, company, locality, suite } = formData;
        const errors = [];
        if (!abnRegex.test(abn)) {
            errors.push('Invalid ABN. It should be 11 digits.');
        }
        if (!contactNumberRegex.test(contact_number)) {
            errors.push('Invalid contact number. It should start with "04" and be 10 digits long.');
        }
        if (!postcodeRegex.test(postal_code)) {
            errors.push('Invalid postcode. It should be a valid 4 digit number.');
        }
        if (!stateRegex.test(administrative_area_level_1.toLowerCase())) {
            errors.push('Invalid state. It should be a valid Australian state or territory abbreviation.');
        }
        if (!countryRegex.test(country.toLowerCase())) {
            errors.push('Invalid country. It should be Australia.')
        }
        if (!stringRegex.test(given_name.toLowerCase())) {
            errors.push('Invalid first name.')
        }
        if (!stringRegex.test(family_name.toLowerCase())) {
            errors.push('Invalid last name.')
        }
        if (!companyRegex.test(company)) {
            errors.push('Invalid company name.');
        }
        if (!localityRegex.test(locality)) {
            errors.push('Invalid address.');
        }
        if (suite && !suiteRegex.test(suite)) {
            errors.push('Invalid suite/apartment number.');
        }
        if (errors.length > 0) {
            errors.forEach((error, index) => {
                setTimeout(() => {
                    showAlertMessage('warning', error);
                }, index * 10); // Slight delay to create distinct alerts
            });
            return false;
        }
        return true
    };

    const validateFormData3 = () => {
        const { abn, contact_number, postal_code, administrative_area_level_1, country, given_name, family_name, company, locality, suite } = formData3;
        const errors = [];
        if (!abnRegex.test(abn)) {
            errors.push('Invalid ABN. It should be 11 digits.');
        }
        if (!contactNumberRegex.test(contact_number)) {
            errors.push('Invalid contact number. It should start with "04" and be 10 digits long.');
        }
        if (!postcodeRegex.test(postal_code)) {
            errors.push('Invalid postcode. It should be a valid 4 digit number.');
        }
        if (!stateRegex.test(administrative_area_level_1.toLowerCase())) {
            errors.push('Invalid state. It should be a valid Australian state or territory abbreviation.');
        }
        if (!countryRegex.test(country.toLowerCase())) {
            errors.push('Invalid country. It should be Australia.')
        }
        if (!stringRegex.test(given_name.toLowerCase())) {
            errors.push('Invalid first name.')
        }
        if (!stringRegex.test(family_name.toLowerCase())) {
            errors.push('Invalid last name.')
        }
        if (!companyRegex.test(company)) {
            errors.push('Invalid company name.');
        }
        if (!localityRegex.test(locality)) {
            errors.push('Invalid address.');
        }
        if (suite && !suiteRegex.test(suite)) {
            errors.push('Invalid suite/apartment number.');
        }
        if (errors.length > 0) {
            errors.forEach((error, index) => {
                setTimeout(() => {
                    showAlertMessage('warning', error);
                }, index * 10); // Slight delay to create distinct alerts
            });
            return false;
        }
        return true
    };


    const goToPreviousStep = () => setCurrentStep(prev => Math.max(1, prev - 1));
    const goToNextStep = () => {
        if (currentStep === 1) {
            if (!validateFormData()) {
                return;
            }
        }
        if (currentStep === 2) {
            if (!validateFormData3()) {
                return;
            }
        }
        
        setCurrentStep(prev => Math.min(4, prev + 1));
    }
    const goToStep = (data) => setCurrentStep(data)
    const goToEquipment = () => navigate('/customer')
    

    return (
        <BreadCrumbContext.Provider value={{ currentStep, orderNumber, setOrderNumber, goToStep, goToPreviousStep, goToNextStep, goToEquipment }}>
            {children}
        </BreadCrumbContext.Provider>
    );
}

export { BreadCrumbContext, BreadCrumbProvider };