import React, { useEffect, useState, useContext } from 'react'
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faMinus, faChevronRight, faChevronDown, faImage } from '@fortawesome/free-solid-svg-icons';

import './css/sideBarComponent.css'
import { BreadCrumbContext } from "./context/BreadCrumbContext";

import { AlertContext } from "./../body/customerBody/AlertContext"

import { useAuth } from './../AuthContext.js'

import { useCheckoutImageContext } from "./context/CheckoutImageContext.js"



const apiUrl = process.env.REACT_APP_API_URL;

function SideBarComponent() {
    const [cookies, setCookies] = useCookies(['cart_cookie', 'csrf_token', 'user']); // this is the index to retrieve data
    const [cartItems, setCartItems] = useState([]);  // For storing cart items from the database, this is the retrieved data
    const [grandTotal, setGrandTotal] = useState([]);
    const [subTotal, setSubtotal] = useState([])
    const [GST, setGST] = useState([]);
    const { currentStep } = useContext(BreadCrumbContext);

    const { setUser } = useAuth();

    const { showAlertMessage } = useContext(AlertContext);

    const [showConfigs, setShowConfigs] = useState({});
    const toggleConfigs = (index) => {
      setShowConfigs((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    };

    useEffect(() => {
        if (cookies.cart_cookie) {
            const cartItems = cookies.cart_cookie;
            fetchData(cartItems)
        } else {
          setCookies('cart_cookie', [], {
            path: '/',
            expires: new Date(Date.now() + 259200000),
            secure: true,
            httpOnly: false,
            sameSite: 'lax'
          })
        }
    }, [cookies]);

    const upArrow = (index) => {
      let shouldUpdateCookies = false;
    
      const updatedCartItems = cookies.cart_cookie.map((item, i) => {
        if (i === index) {    
          if (item.quantity < cartItems[index].available_quantity) {
            shouldUpdateCookies = true;
            return { ...item, quantity: item.quantity + 1 };
          } else {
            showAlertMessage('warning', `Cannot exceed the available quantity for item ${item.id}`);
            //alert(`Cannot exceed the available quantity for item ${item.id}`);
          }
        }
        return item;
      });
    
      if (shouldUpdateCookies) {
        setCookies('cart_cookie', JSON.stringify(updatedCartItems), {
          path: '/',
          expires: new Date(Date.now() + 259200000),
          secure: true,
          httpOnly: false,
          sameSite: 'lax' // Default is false, explicitly stated here for clarity
        });
      }
    };
    
    
    const downArrow = (index) => {
        const updatedCartItems = cookies.cart_cookie.map((item, i) => {
            if (i === index) {
                const newQuantity = item.quantity > 1 ? item.quantity - 1 : 1; // Ensuring quantity doesn't drop below 1
                return {...item, quantity: newQuantity};
            }
            return item;
        });
        setCookies('cart_cookie', JSON.stringify(updatedCartItems), { 
            path: '/', 
            expires: new Date(Date.now() + 259200000), 
            secure: true, 
            httpOnly: false, // Default is false, explicitly stated here for clarity
            sameSite: 'lax'
        });
    };
    
    const removeItem = (index) => {
        const updatedCartItems = cookies.cart_cookie.filter((item, i) => i !== index);
        setCookies('cart_cookie', JSON.stringify(updatedCartItems), { 
            path: '/', 
            expires: new Date(Date.now() + 259200000), 
            secure: true, 
            httpOnly: false,
            sameSite: 'lax' // Default is false, explicitly stated here for clarity
        });
    }

    const fetchData = async (cartItems) => {
      try {
          const response = await fetch(`${apiUrl}/api/paymentPortalRequest`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': cookies.csrf_token,
              },
              body: JSON.stringify({ cartItems }),
              credentials: 'include',
          });
          if (response.status === 400) {
              setUser(null);
              return;
          }
          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("application/json")) {
              console.error("Unexpected response format:", response);
              return;
          }
          const data = await response.json();
          if (data.success === true) {
              setCartItems(data.items);
              setGrandTotal(data.grandTotal);
              setGST(data.gst);
              setSubtotal(data.subTotal);
              if (data.removeItems.length > 0) {
                //remove the items from the cookie
                const updatedCartItems = cartItems.filter(
                    item => !data.removeItems.includes(item.id)
                );
                setCookies('cart_cookie', updatedCartItems, { 
                  path: '/',
                  expires: new Date(Date.now() + 259200000), 
                  secure: true, 
                  httpOnly: false,
                  sameSite: 'lax' 
                });
              }
          } else {
              console.error("Data success is false:", data);
          }
          return data;
      } catch (error) {
          if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
              console.error('Fetch failed. Possible network error or CORS issue:', error);
          } else {
              console.error('An unexpected error occurred:', error);
          }
      } finally {
      }
    };

    const incrementQuantity = (rowIndex, configIndex) => {
      const updatedCartItems = [...cookies.cart_cookie];
      let shouldUpdateCookies = false;
      if (updatedCartItems[rowIndex].quantity + 1 > cartItems[rowIndex].available_quantity) {
        //alert(`Cannot exceed the available quantity for item ${cartItems[rowIndex].part_number_supplier}`)
        showAlertMessage('warning', `Cannot exceed the available quantity for item ${cartItems[rowIndex].part_number_supplier}`);
      } else {
        shouldUpdateCookies = true
      }
      if (shouldUpdateCookies) {
        updatedCartItems[rowIndex].configurations[configIndex].quantity += 1;
        updatedCartItems[rowIndex].quantity += 1;
        setCookies('cart_cookie', JSON.stringify(updatedCartItems), { 
            path: '/', 
            expires: new Date(Date.now() + 259200000), 
            secure: true, 
            httpOnly: false,
            sameSite: 'lax' 
        });
      }
      
    };
    
    const decrementQuantity = (rowIndex, configIndex) => {
      const updatedCartItems = [...cookies.cart_cookie];
      if (updatedCartItems[rowIndex].configurations[configIndex].quantity > 1) {
        updatedCartItems[rowIndex].configurations[configIndex].quantity -= 1;
        updatedCartItems[rowIndex].quantity -= 1;
      }
      setCookies('cart_cookie', JSON.stringify(updatedCartItems), { 
          path: '/', 
          expires: new Date(Date.now() + 259200000), 
          secure: true, 
          httpOnly: false,
          sameSite: 'lax' 
      });
    };
    
    const removeConfiguration = (rowIndex, configIndex) => {
      const updatedCartItems = [...cookies.cart_cookie];
      updatedCartItems[rowIndex].quantity -= updatedCartItems[rowIndex].configurations[configIndex].quantity;
      updatedCartItems[rowIndex].configurations.splice(configIndex, 1);
      if (updatedCartItems[rowIndex].configurations.length === 0) {
        toggleConfigs(rowIndex)
        updatedCartItems.splice(rowIndex, 1);
      }

      setCookies('cart_cookie', JSON.stringify(updatedCartItems), { 
          path: '/', 
          expires: new Date(Date.now() + 259200000), 
          secure: true, 
          httpOnly: false,
          sameSite: 'lax'
      });
    };


    const { images, addImages } = useCheckoutImageContext();

    useEffect(() => {
      const fetchImages = async () => {
        const uniqueImageNames = Array.from(new Set(
          cookies.cart_cookie.map(row => row.imagename).filter(name => name && !images[name])
        ));
        if (uniqueImageNames.length > 0) {
          try {
            const response = await fetch(`${apiUrl}/api/get-images`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': cookies.csrf_token, 
              },
              body: JSON.stringify({ image_names: uniqueImageNames }),
              credentials: 'include'
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            addImages(data);
          } catch (error) {
            console.error('Error fetching images:', error);
          }
        }
      };
      fetchImages();
    }, []);

    return (
        <div className="order-summary__sections" style={{ display: 'flex', flexDirection: 'column', maxHeight: '60%' }}>
            <div className="order-summary__section__content" style={{ overflow: 'auto', maxHeight: '900px' }}>
                <div className="order-summary__section order-summary__section--product-list" >
                    <table className="product-table" >
                        <thead>
                            <tr>
                                <th scope="col"><span className='visually-hidden'>Product Image</span></th>
                                <th scope="col"><span className='visually-hidden'>Product Image</span></th>
                                <th scope="col"><span className='visually-hidden'>Product Image</span></th>
                                <th scope="col"><span className='visually-hidden'>Product Image</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems.map((row, rowIndex) => (
                              <React.Fragment key={rowIndex}>
                                <tr>
                                  <td style={{paddingBottom: "10px", minWidth: "100px"}}>
                                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                                      <div style={{position: 'relative', height: '100px', width: '100px', alignSelf: 'flex-start', display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                                      {images[row.image_name] ? (
                                      <img
                                        src={`data:image/jpeg;base64,${images[row.image_name]}`}
                                        alt="Product"
                                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                                      />
                                      ) : (
                                        <FontAwesomeIcon 
                                          icon={faImage} 
                                          size="lg" 
                                          style={{"color": "#ddd", width: "100px", height: "100px"}}
                                        />
                                        //<Skeleton style={{ width: '100px', height: '100px' }} />
                                      )}
                                        <span style={{zIndex: 999, position: 'absolute', top: '0', right: '0', background: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '2px 5px', fontSize: '12px', borderRadius: '0 0 0 8px', minWidth: "15px" }}>{row.quantity}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{paddingBottom: "10px", minWidth: "100%"}}>
                                      <div style={{display: 'flex', flexDirection: 'column'}}>
                                          <span>{row.company}</span>
                                          <span>{row.part_name}</span>
                                          <span>{row.part_number_supplier}</span>
                                          {row.configurations ? (
                                            <div className="flex items-center" style={{alignItems: "center"}}>
                                              {showConfigs[rowIndex] ? 
                                                (
                                                  <div className="navbar-close bg-transparent border-none cursor-pointer" style={{display: "flex"}}>
                                                    <FontAwesomeIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faChevronDown} />
                                                  </div>
                                                ) 
                                                : 
                                                (
                                                  <div className="navbar-close bg-transparent border-none cursor-pointer" style={{display: "flex"}}>
                                                    <FontAwesomeIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faChevronRight} />
                                                  </div>
                                                )
                                              }
                                              <span>
                                                <button 
                                                  className="ml-2" 
                                                  style={{alignItems: "center", border: 'none', background: 'transparent', cursor: 'pointer', color: '#535353', marginLeft: '0px'}} 
                                                  onClick={() => toggleConfigs(rowIndex)}>
                                                    <span style={{fontSize: "14px", fontFamily: "Helvetica"}}>
                                                      Configurations
                                                    </span>
                                                </button>
                                              </span>
                                              {!showConfigs[rowIndex] && (
                                                <td style={{ marginLeft: "auto",textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                  <div style={{ marginRight: '10px' }}>
                                                    ${(parseFloat(row.price) * parseFloat(row.quantity) + row.configurations.reduce((sum, config) => sum + parseFloat(config.price) * parseFloat(config.quantity), 0)).toFixed(2)}
                                                  </div>
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                                      <FontAwesomeIcon
                                                        className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                                                        onClick={() => removeItem(rowIndex)}
                                                        style={{ display: 'flex', alignItems: 'center' }} />
                                                    </button>
                                                  </div>
                                                </td>
                                              )}
                                            </div>
                                          ) : (
                                            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                              <div style={{display: 'flex', alignItems: 'center'}}>
                                                <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0 }}>
                                                  <FontAwesomeIcon 
                                                    className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faMinus}
                                                    onClick={() => downArrow(rowIndex)} />
                                                </button>
                                              </div>
                                              <div style={{ minWidth: "17px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {row.quantity}
                                              </div>
                                              <div style={{display: 'flex', alignItems: 'center'}}>
                                                <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                  <FontAwesomeIcon 
                                                    className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faPlus}
                                                    onClick={() => upArrow(rowIndex)} />
                                                </button>
                                              </div>
                                              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                  ${(parseFloat(row.price) * parseFloat(row.quantity)).toFixed(2)}
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                  <button className="navbar-close bg-transparent border-none cursor-pointer" style={{"padding": 0}}>
                                                    <FontAwesomeIcon 
                                                      className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                                                      onClick={() => removeItem(rowIndex)} 
                                                      style={{ display: 'flex', alignItems: 'center' }} />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                      </div>  
                                  </td>
                                </tr>
                                {showConfigs[rowIndex] && (
                                  <tr>
                                    <td colSpan="3" style={{borderTop: "solid 1px #E1E1E1"}}>
                                      {row.configurations.map((config, configIndex) => (
                                        <div key={configIndex} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                                          <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                            {Object.values(config.configuration).join('-')}
                                          </div>
                                          <div style={{display: 'flex', alignItems: 'center'}}>
                                            <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                              <FontAwesomeIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faMinus}
                                                onClick={() => decrementQuantity(rowIndex, configIndex)} />
                                            </button>
                                          </div>
                                          <div style={{ minWidth: "17px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {config.quantity}
                                          </div>
                                          <div style={{display: 'flex', alignItems: 'center'}}>
                                            <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                              <FontAwesomeIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faPlus}
                                                onClick={() => incrementQuantity(rowIndex, configIndex)} />
                                            </button>
                                          </div>
                                          <div style={{ display: 'flex', alignItems: 'center', width: '95px' }}>
                                            ${(parseFloat(config.price) + parseFloat(row.price)).toFixed(2)}(ea)
                                          </div>
                                          <div style={{display: 'flex', alignItems: 'center'}}>
                                            <button className="navbar-close bg-transparent border-none cursor-pointer">
                                              <FontAwesomeIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                                                onClick={() => removeConfiguration(rowIndex, configIndex)} />
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                    </td>
                                  </tr>
                                )}
                                {showConfigs[rowIndex] && (
                                  <tr>
                                    <td colSpan="3" style={{borderTop: "solid 1px #E1E1E1", textAlign: "right"}}>
                                    Configuration Subtotal: ${(parseFloat(row.price) * parseFloat(row.quantity) + row.configurations.reduce((sum, config) => sum + parseFloat(config.price) * parseFloat(config.quantity), 0)).toFixed(2)} (ex GST)
                                    </td>                                    
                                  </tr>
                                )}

                              </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>     
            <div class="order-summary__section order-summary__section--total-lines" data-order-summary-section="payment-lines" style={{paddingBottom: "100px"}}>
              <table className={`total-line-table ${currentStep === 4 ? 'visually-hidden' : ''}`} >
                <caption class="visually-hidden">Cost summary</caption>
                <thead>
                  <tr>
                    <th scope="col"><span class="visually-hidden">Description</span></th>
                    <th scope="col"><span class="visually-hidden">Price</span></th>
                  </tr>
                </thead>
                <tbody class="total-line-table__tbody">
                  <tr class="total-line total-line--subtotal">
                      <th class="total-line__name" scope="row">Subtotal</th>
                      <td class="total-line__price" style={{textAlign: "right"}}>
                        <span class="order-summary__emphasis skeleton-while-loading">${subTotal}</span>
                      </td>
                  </tr>
                  <tr class="total-line total-line--taxes" data-checkout-taxes="">
                    <th class="total-line__name" scope="row">Estimated taxes (GST)</th>
                    <td class="total-line__price" style={{textAlign: "right"}}>
                      <span class="order-summary__emphasis skeleton-while-loading">${GST}</span>
                    </td>
                  </tr>
                  <tr class="total-line total-line--shipping">
                    <th class="total-line__name" scope="row">
                      <span>Shipping</span>
                    </th>
                    <td class="total-line__price" style={{textAlign: "right"}}>
                      <span class="skeleton-while-loading order-summary__emphasis" >Calculated on invoice</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot class="total-line-table__footer" >
                  <tr class="total-line">
                    <th class="total-line__name payment-due-label" scope="row">
                      <span class="payment-due-label__total">Total</span>
                    </th>
                    <td class="total-line__price payment-due" data-presentment-currency="AUD" style={{textAlign: "right"}}>
                      <span class="payment-due__currency remove-while-loading">AUD</span>
                      <span class="payment-due__price skeleton-while-loading--lg"> ${grandTotal}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>       
        </div>
    );
    
}


export default SideBarComponent



//"｡☆✼★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★✼☆｡ \n (｡•́︿•̀｡) Too many items in your cart! (｡•́︿•̀｡) \n ｡☆✼★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★✼☆｡"