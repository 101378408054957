import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';
import InputWithUnitSelector from '../inputBoxWithSelectorComponent.tsx';
import InputBoxComponent from '../InputBoxComponent.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEquipment } from './../body/customerBody/CombinedContext.js';


import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface ConnectionDataFilterProps {
  connectionData: any;
  setConnectionData: (data: any) => void;
}

//const defaultConnectionData = {
//  "connection1": { "label": "Connection 1", "selectors": "processconnections", "units": "DN" },
//};

const ConnectionDataFilter: React.FC<ConnectionDataFilterProps> = ({ connectionData, setConnectionData }) => {
  
  const { partType } = useEquipment();
  const { fetchOperatingData, sampleConnectionDataState, loaded } = useEquipment();

  useEffect(() => {
    fetchOperatingData(partType, 'connectiondata')
  }, [partType]);

  const initializeValues = (data: typeof sampleConnectionDataState, connectionData: any) => {
    const initialValues: { [key: string]: string | number } = {};
    Object.keys(data).forEach(key => {
      initialValues[key] = connectionData[key]?.size || '';
    });
    return initialValues;
  };
  
  const initializeUnitValues = (data: typeof sampleConnectionDataState, connectionData: any) => {
    const initialUnitValues: { [key: string]: string | null } = {};
    Object.keys(data).forEach(key => {
      initialUnitValues[key] = connectionData[key]?.units || data[key].units || '';
    });
    return initialUnitValues;
  };
  
  const initializeTypeValues = (data: typeof sampleConnectionDataState, connectionData: any) => {
    const initialTypeValues: { [key: string]: string } = {};
    Object.keys(data).forEach(key => {
      initialTypeValues[key] = connectionData[key]?.type || '';
    });
    return initialTypeValues;
  };

  const [selection, setSelection] = useState<number>(0);
  const [values, setValues] = useState<{ [key: string]: string | number }>(initializeValues(sampleConnectionDataState, connectionData));
  const [units, setUnits] = useState<{ [key: string]: string | null }>(initializeUnitValues(sampleConnectionDataState, connectionData));
  const [types, setTypes] = useState<{ [key: string]: string }>(initializeTypeValues(sampleConnectionDataState, connectionData));
  const [fullView, setFullView] = useState<boolean>(false);

  useEffect(() => {
    if (connectionData === 'reset') {
      resetState();
    }
  }, [connectionData]);

  const resetState = useCallback(() => {
    setValues(initializeValues(sampleConnectionDataState, {}));
    setUnits(initializeUnitValues(sampleConnectionDataState, {}));
    setTypes(initializeTypeValues(sampleConnectionDataState, {}));
    setConnectionData({});
  }, [sampleConnectionDataState, setConnectionData]);

  useEffect(() => {
    let updatedConnectionData: any = {};

    Object.keys(sampleConnectionDataState).forEach(key => {
      if (values[key] !== '' || types[key] !== '') {
        updatedConnectionData[key] = { size: values[key], type: types[key], units: units[key] };
      }
    });

    if (Object.keys(updatedConnectionData).length === 0) {
      updatedConnectionData = "";
    }

    setConnectionData(updatedConnectionData);
  }, [values, units, types, setConnectionData]);

  const handleSelectionChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelection(parseInt(e.target.value, 10));
  }, []);

  const handleValueChange = useCallback((key: string) => (value: string | number) => {
    setValues(prevValues => ({ ...prevValues, [key]: value }));
  }, []);

  const handleUnitChange = useCallback((key: string) => (unit: string | null) => {
    setUnits(prevUnits => ({ ...prevUnits, [key]: unit }));
  }, []);

  const handleTypeChange = useCallback((key: string) => (type: string) => {
    setTypes(prevTypes => ({ ...prevTypes, [key]: type }));
  }, []);

  const renderInputComponent = useCallback((key: string, index: number) => {
    const selectedData = sampleConnectionDataState[key];
    return (
      <div key={key}>
        <InputWithUnitSelector
          value={values[key] || ''}
          setValue={handleValueChange(key)}
          unit={units[key] || ''}
          setUnit={handleUnitChange(key)}
          label={`Size ${index + 1}`}
          selectors={selectedData.selectors}
        />
        <InputBoxComponent
          string={types[key] || ''}
          setString={handleTypeChange(key)}
          label={`Type ${index + 1}`}
          regexType='string'
        />
      </div>
    );
  }, [sampleConnectionDataState, values, units, types, handleValueChange, handleUnitChange, handleTypeChange]);

  const toggleView = useCallback(() => {
    setFullView(prevFullView => !prevFullView);
  }, []);

  return (
    <div>
      {!sampleConnectionDataState || !loaded ? (
        <div>
          <div style={{ fontFamily: 'inherit', fontSize: '14px', marginBottom: '3px', display: "flex", flexDirection: 'row', paddingTop: '2px' }}>
            <div style={{ width: "100%" }}>Connection Data:</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton height={20} width={20} />
            </div>
          </div>
          <div style={{ marginBottom: '5px' }}>
            <Skeleton height={18} width={178} />
          </div>
          <div style={{ marginBottom: '5px' }}>
            <Skeleton height={36} width={178} />
          </div>
          <div style={{ paddingBottom: '5px', marginBottom: '5px' }}>
            <Skeleton height={36} width={178} />
          </div>
        </div>
      ) : (
        <div>
          <div style={{ fontSize: '14px', marginBottom: '5px', display: "flex", flexDirection: 'row' }}>
            <div style={{ width: "100%" }}>
              <button
                onClick={toggleView}
                style={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                  padding: 0,
                  fontFamily: 'inherit',
                  fontSize: '14px',
                  color: fullView ? '#339AF0' : 'black',
                  fontWeight: fullView ? 'bold' : ''
                }}
              >
                Process Connections:
              </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                <FontAwesomeIcon
                  className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                  onClick={() => resetState()}
                  style={{ display: 'flex', alignItems: 'center' }} />
              </button>
            </div>
          </div>

          <div>
            {fullView ? (
              Object.keys(sampleConnectionDataState).map((key, index) => (
                <div key={key}>
                  {renderInputComponent(key, index)}
                </div>
              ))
            ) : (
              <>
                <div>
                  <select
                    value={selection}
                    onChange={handleSelectionChange}
                    style={{ marginBottom: '5px', width: "100%", fontSize: '14px', border: '1px solid #ccc', borderRadius: '4px' }}
                  >
                    {Object.keys(sampleConnectionDataState).map((key, index) => (
                      <option key={key} value={index}>{sampleConnectionDataState[key].label}</option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {renderInputComponent(`connection${selection + 1}`, selection)}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectionDataFilter;
