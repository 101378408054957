import React, { useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
//import CustomAlert from './../../../components/CustomAlertComponent';
import  { AlertContext } from './AlertContext'

function AddToCartButton({ id, company, partType, availableQuantity, imagename, configuration, configurationLength, setClickedSelectedOption}) {
    const [cookies, setCookie] = useCookies(['cart_cookie']);
    const [isAdded, setIsAdded] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [quantity2, setQuantity2] = useState(1);

    const { showAlertMessage } = useContext(AlertContext);
    
    
    useEffect(() => {
        const cartItems = cookies.cart_cookie || [];
        const item = cartItems.find(item => item.id === id);
        setQuantity(item ? item.quantity : 0)
    },[id])

    const buttonStyle = {
        backgroundColor: "white",
        color: "black",
        border: "1px solid #ccc",
        borderRadius: "4px",
        boxSizing: "border-box",
        padding: "10px",
        minWidth: "10px",
        cursor: "pointer",
        textAlign: "center"
    };

    useEffect(() => {
        const cartItems = cookies.cart_cookie || [];
        const item = cartItems.find(item => item.id === id);
        if (item) {
            setIsAdded(true);
            setQuantity(item.quantity);
        }
    }, [id, cookies.cart_cookie]);

    const updateCart = (newQuantity) => {
        const cartItem = {
            id,
            company_name: company,
            part_type: partType,
            quantity: newQuantity,
            imagename: imagename ? imagename : null,
            configurations: configuration ? [{
                configuration: configuration,
                quantity: newQuantity
            }] : []
        };

        let cartItems = [];

        if (cookies.cart_cookie) {
            try {
                if (typeof cookies.cart_cookie === 'object' && cookies.cart_cookie !== null) {
                    cartItems = cookies.cart_cookie;
                } else {
                    cartItems = JSON.parse(cookies.cart_cookie);
                }
            } catch (e) {
                console.error("Error parsing cart data: ", e);
                cartItems = [];
            }
        }

        const existingCartItemIndex = cartItems.findIndex(item =>
            item.id === cartItem.id &&
            item.company_name === cartItem.company_name &&
            item.part_type === cartItem.part_type
        );

        if (existingCartItemIndex >= 0) {
            const existingCartItem = cartItems[existingCartItemIndex];
            existingCartItem.quantity = newQuantity;
            if (configuration) {
                const totalQuantity = existingCartItem.configurations.reduce((sum, config) => sum + config.quantity, 0);
                const existingConfigIndex = existingCartItem.configurations.findIndex(config =>
                    JSON.stringify(config.configuration) === JSON.stringify(configuration)
                );
                if (existingConfigIndex >= 0) {
                    existingCartItem.configurations[existingConfigIndex].quantity += newQuantity - totalQuantity;
                } else {
                    existingCartItem.configurations.push({ configuration: configuration, quantity: newQuantity - totalQuantity });
                }
            }
        } else {
            cartItems.push(cartItem);
        }

        if (newQuantity === 0) {
            cartItems = cartItems.filter(item => item.id !== id);
        }

        setCookie('cart_cookie', JSON.stringify(cartItems), {
            path: '/',
            expires: new Date(Date.now() + 259200000),
            secure: true,
            httpOnly: false,
            sameSite: 'lax' // Default is false, explicitly stated here for clarity
        });
    }

    const handleQuantity2Change = (event) => {
        const newQuantity2 = Math.max(1, Math.min(availableQuantity - quantity, parseInt(event.target.value, 10)));
        setQuantity2(newQuantity2);
    };

    const incrementQuantity = () => {
        const totalNewQuantity = quantity + quantity2;
        if (totalNewQuantity <= availableQuantity) {
            setQuantity(totalNewQuantity);
            updateCart(totalNewQuantity);
        } else {
            showAlertMessage('warning', `Cannot exceed the available quantity for item ${id}`);
        }
    };

    const decrementQuantity = () => {
        const totalNewQuantity = Math.max(0, quantity - quantity2);
        if (totalNewQuantity === 0) {
            setIsAdded(false);
        }
        setQuantity(totalNewQuantity);
        updateCart(totalNewQuantity);
    };

    const handleRemove = (itemId, configIndex) => {
        setIsAdded(false);
        const updatedCart = cookies.cart_cookie.map(item => {
            if (item.id === itemId) {
                const configToRemove = item.configurations[configIndex];
                const updatedConfigurations = item.configurations.filter((_, index) => index !== configIndex);
                const updatedQuantity = item.quantity - configToRemove.quantity;

                if (updatedConfigurations.length === 0) {
                    // If no configurations left, return null to filter out later
                    return null;
                }

                return { ...item, configurations: updatedConfigurations, quantity: updatedQuantity };
            }
            return item;
        }).filter(item => item !== null);
        setCookie('cart_cookie', updatedCart, { 
            path: '/',
            expires: new Date(Date.now() + 259200000),
            secure: true,
            httpOnly: false,
            sameSite: 'lax' 
        });
        // Find the updated item and set the new quantity
        const updatedItem = updatedCart.find(item => item.id === id);
        setQuantity(updatedItem ? updatedItem.quantity : 0);
    };

    return (
        <div style={{ width: "100%", justifyContent: "center" }}>
            <div style={{display: "flex",justifyContent: 'center', width: '100%'}}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex'}}>
                  <span style={{ marginRight: '10px', width: "120px", textAlign: 'left' }}>{id} in Cart:</span> 
                  
                  <span style={{ textAlign: "left", width: "50px"}}>{quantity ? quantity : 0}</span> 
                </div>
              </div>
            </div>
            <style>
              {`
                input[type=number]::-webkit-outer-spin-button,
                input[type=number]::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
            
                input[type=number] {
                  -moz-appearance: textfield;
                }
              `}
            </style>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <button 
                  onClick={decrementQuantity} 
                  style={{ 
                    ...buttonStyle, 
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0
                }}
                  disabled={configuration ? Object.keys(configuration).length < configurationLength : false}
                >
                  <FontAwesomeIcon icon={faMinus}/>
              </button>
              <input 
                type="number" 
                value={quantity2} 
                onChange={handleQuantity2Change} 
                style={{ 
                  textAlign: 'center', 
                  width: '50px', 
                  padding: '10px', 
                  border: '1px solid #ccc', // Optional: styling border for consistency
                  outline: 'none', // Optional: removing default outline
                  borderLeft: 'none',
                  borderRight: 'none'
                }} 
              />
              <button 
                onClick={incrementQuantity} 
                style={{ 
                    ...buttonStyle, 
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0
                }}
                
                disabled={configuration ? Object.keys(configuration).length < configurationLength : false}
              >
                <FontAwesomeIcon icon={faPlus}/>
              </button>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                {isAdded && cookies.cart_cookie && configuration && (
                    <div style={{width: "100%"}}>
                        <div style={{justifyContent: "center", display: "flex"}}>Configurations:</div>
                        {cookies.cart_cookie.map((item, index) => (
                            item.id === id && item.company_name === company && item.part_type === partType && item.configurations.map((config, configIndex) => (
                                <div key={configIndex} style={{ justifyContent: "center", display: "flex" }}> 
                                    <div style={{width: '180px'}}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex'}}>
                                            <button 
                                                style={{display:"flex", flexGrow: 1, maxWidth: '180px', marginRight: "10px", padding: 0, background: 'none', border: 'none', alignItems: 'center', cursor: 'pointer'}} 
                                                onClick={() => setClickedSelectedOption(config.configuration)}>
                                                {Object.entries(config.configuration || {}).map(([designation, optionDesignation], index, array) => (
                                                    <span key={designation}>
                                                        {optionDesignation}
                                                        {index < array.length - 1 ? '-' : ''}
                                                    </span>
                                                ))}   
                                            </button>
                                            <div style={{display:"flex"}}>
                                                <div style={{display: "flex", width: "60px"}}>
                                                    <span style={{flexGrow: 1, textAlign: "right"}}>
                                                        {config.quantity}
                                                    </span>
                                                    <button className="navbar-close bg-transparent border-none cursor-pointer" onClick={() => handleRemove(item.id, configIndex)}>
                                                      <FontAwesomeIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            ))
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default AddToCartButton;
