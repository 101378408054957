import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// Initialize the context with default values
const apiUrl = process.env.REACT_APP_API_URL;

const AuthContext = createContext({ user: null, setUser: () => {} });

export const AuthProvider = ({ children }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user', 'csrf_token']);
    const navigate = useNavigate();
    const location = useLocation();
    
    const [user, setUser] = useState(() => {
        const csrfToken = cookies.csrf_token;
        const savedUserData = cookies.user;
        if (savedUserData) {
            try {
                // Ensure savedUserData is parsed correctly
                const parsedUserData = typeof savedUserData === 'string' ? JSON.parse(savedUserData) : savedUserData;
                const { expirationDate, ...userData } = parsedUserData;
                if (new Date(expirationDate) > new Date()) {
                    return { ...userData, csrfToken };
                } else {
                    removeCookie('user', { path: '/' });
                    removeCookie('csrf_token', { path: '/'});
                    navigate('/login')
                    return null;
                }
            } catch (e) {
                console.error('Error parsing saved user data:', e);
                removeCookie('user', { path: '/' });
                removeCookie('csrf_token', { path: '/'});
                navigate('/login')
                return null;
            }
        }
        return null;
    });

    useEffect(() => {
        if (user) {
            // User is logged in
            const expirationDate = new Date();
            expirationDate.setHours(expirationDate.getHours() + 1); // Set expiration time (e.g., 1 hour from now)
            const userDataWithExpiration = { ...user, expirationDate: expirationDate.toISOString() };
            setCookie('user', JSON.stringify(userDataWithExpiration), { path: '/', expires: expirationDate, secure: true, sameSite: 'Lax' });
        } else {
            if (location.pathname !== '/' && location.pathname !== '/login') {
                navigate('/login');
            }
            fetch(`${apiUrl}/api/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': cookies.csrf_token, // Ensure CSRF token is sent
                },
                credentials: 'include', // Include cookies in the request
            })
            .then(response => response.json())
            .then(data => {
                if (data.message === "Logged out successfully") {
                    removeCookie('user', { path: '/' });
                    removeCookie('csrf_token', { path: '/' });
                    //removeCookie('transaction_id', { path: '/' });
                    setUser(null);
                } else {
                    // Handle error case
                    console.error("Failed to logout:", data);
                }
            })
            .catch(error => {
                console.error("Logout error:", error);
            });
        }
    }, [user, setUser, setCookie, removeCookie, navigate]);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);






