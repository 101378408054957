import React from 'react';
import './css/LandingPageComponent.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faMinus, faChevronRight, faChevronDown, faImage } from '@fortawesome/free-solid-svg-icons';
import ZephyrInnovaitionsLogo2 from './../../assets/Zephyr Innovations Logo 2 (1).svg';

function LandingPageComponent() {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <main className="main-content" style={{}}>
        <section className="intro" style={{ overflowY: 'none' }}>
          <div className="intro-content" style={{ overflowY: 'none' }}>
            <div className="call-to-action" style={{ textAlign: 'left', marginRight: 'auto', fontFamily: 'sans-serif', color: 'white', marginTop: '220px', display: 'flex', fontWeight: 'bold', minHeight: '120px' }}>
              Explore the widest range of engineering equipment in one place
            </div>
            <div style={{ textAlign: 'left', marginRight: 'auto', fontFamily: 'sans-serif', color: 'black', flexDirection: 'row', display: 'flex' }}>
              <div
                className="button-container"
                onClick={() => navigate('/login')}
                style={{ display: 'flex', padding: '10px 30px', background: 'white', border: 'white 1px solid', borderRadius: '20px', cursor: 'pointer', marginRight: '10px' }}
              >
                <button
                  style={{ fontFamily: 'inherit', padding: 0, border: 'none', background: 'transparent', cursor: "pointer", height: '100%', fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: 'medium' }}
                >
                  Start browsing
                </button>
              </div>
              <div className="icon-container" style={{ display: 'flex', border: 'white 1px solid', borderRadius: '20px', width: '39px', height: '39px', alignItems: 'center', justifyContent: "center", background: "white", transition: 'margin-left 0.3s ease' }}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>

            <div style={{ marginTop: 'auto', marginBottom: '0%', marginRight: 'auto' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 'auto' }}>
                <img src={ZephyrInnovaitionsLogo2} alt="Zephyr Innovations Logo" style={{ marginRight: 'auto', maxHeight: '200px', maxWidth: '600px' }} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default LandingPageComponent;

