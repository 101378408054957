import React, {useEffect, useState, useRef} from 'react';
import { useCookies } from 'react-cookie';
import OperatingConditions from './tableDisplay/operatingConditions.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './css/TableComponent.css'
import AddToCartButton from './AddToCartButtonComponent.js';
import { useScroll } from './scrollContext.js'
import ProcessConnections from './tableDisplay/processConnections.js';
import ElectricalData from './tableDisplay/electricalData.js';
import MeasuringData from './tableDisplay/measuringData.js';
import MaterialsData from './tableDisplay/materialsData.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faImage } from '@fortawesome/free-solid-svg-icons';
import { useEquipment } from './CombinedContext.js'; // Import the custom hook
import { useImageContext } from './ImageContext'; 

const apiUrl = process.env.REACT_APP_API_URL;

function calculateVisibleWidth(element) {
  if (!element) return 0;

  const rect = element.getBoundingClientRect();
  const left = Math.max(0, rect.left);
  const right = Math.min(window.innerWidth, rect.right);
  return Math.max(0, right - left);
}

function TableComponentTest(props) {
    const table1Ref = useRef(null);
    const table3Ref = useRef(null);
    
    const [detailsData, setDetailsData] = useState({});
    const { scrollPosition } = useScroll();
    const tableRef = useRef(null);
    const rowRef = useRef(null);
    
    
    const [selectedOption, setSelectedOption] = useState({});
    const [, setSelectedOptions] = useState({});
    const [, setDisabledOptions] = useState({});
    
    
    const [nestedHashmap, setNestedHashmap] = useState({});
    const [cookies] = useCookies(['cart_cookie','csrf_token']);
    const {filtering} = useEquipment();

    const { lastDataElementRef, tableContainerRef, tableTopRef, targetRowRef, firstDataElementRef, targetRowRef2, showDetails, setShowDetails, showConfiguration, setShowConfiguration, headersRef, currentConfigRow, setCurrentConfigRow, selectedButton, setSelectedButton, selectedButton2, setSelectedButton2, expandedRow, setExpandedRow } = useEquipment();
    

    const [cartItems, setCartItems] = useState([]);

    const { images, addImages } = useImageContext();

    useEffect(() => {
      const fetchImages = async () => {
        const uniqueImageNames = Array.from(new Set(
          props.data.map(row => row.imagename).filter(name => name && !images[name])
        ));
        if (uniqueImageNames.length > 0) {
          try {
            const response = await fetch(`${apiUrl}/api/get-images`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': cookies.csrf_token, 
              },
              body: JSON.stringify({ image_names: uniqueImageNames }),
              credentials: 'include'
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            addImages(data);
          } catch (error) {
            console.error('Error fetching images:', error);
          }
        }
      };
      fetchImages();
    }, [props.data]);


    useEffect(() => {
      const storedCartItems = cookies.cart_cookie || [];
      setCartItems(storedCartItems);
    }, [cookies.cart_cookie]);


    useEffect(() => {
      if (filtering === true) {
        setShowConfiguration(false)
        setShowDetails(false)
        setExpandedRow(null)
        setSelectedButton2(null)
      }
    },[filtering])

    
    const normalDatabaseHeaders = props.databaseHeaders.filter(header => ['operatingconditions', 'connectiondata', "electricaldata", "measuringdata"].includes(header))

    const showOperatingConditions = props.databaseHeaders.some(header => normalDatabaseHeaders.includes(header));
    const showProcessConnections = props.databaseHeaders.some(header => normalDatabaseHeaders.includes(header));
    const showElectricalData = props.databaseHeaders.some(header => normalDatabaseHeaders.includes(header))
    const showMeasuringData = props.databaseHeaders.some(header => normalDatabaseHeaders.includes(header))

    const [activeConfigIndex, setActiveConfigIndex] = useState(0);
  
    useEffect(() => {
      if (!showDetails && !showConfiguration && tableRef.current && headersRef.current) {
        tableRef.current.scrollLeft = scrollPosition; 
        headersRef.current.scrollLeft = scrollPosition;
      }
    }, [scrollPosition, showDetails]);

    useEffect(() => {
      if (!showDetails && !showConfiguration && tableRef.current && table1Ref.current && table3Ref.current) {
        const updateWidth = () => {
          const tableValue = tableRef.current.offsetWidth
          const rowValue = rowRef.current.offsetWidth
          const result = (rowValue/tableValue) * 100
          props.setTableWidth(result);
        };

        const updateVisibleWidth = () => {
          const hasVerticalScrollbar = tableRef.current.clientHeight > tableRef.current.scrollHeight;
          const scrollbarWidth = hasVerticalScrollbar ? 0 : 20;
          props.setTable1VisibleWidth(calculateVisibleWidth(table1Ref.current) + 5);
          props.setTable3VisibleWidth(calculateVisibleWidth(table3Ref.current) + scrollbarWidth);
        };
        updateVisibleWidth(); // Update on mount
        updateWidth();
        window.addEventListener('resize', updateWidth);
        window.addEventListener('resize', updateVisibleWidth);
        return () => {
          window.removeEventListener('resize', updateWidth);
          window.removeEventListener('resize', updateVisibleWidth);
        }
      }
    }, [props.data, tableRef, showDetails, showConfiguration, props.isPartTypeVisible, props.isFilterVisible]);

    useEffect(() => {
      setSelectedOption({})
      setSelectedButton(0)
    },[showConfiguration])

    const renderCell = (row, header) => {
      const dataKey = header;
      const cellData = row[dataKey];
      switch (header) {
        case 'electricaldata':
          return <ElectricalData inputArray={cellData} />
        case 'connectiondata':
          return <ProcessConnections connections={cellData} />
        case 'measuringdata':
          return <MeasuringData inputArray={cellData} />
        case 'operatingconditions':
          return <OperatingConditions inputArray={cellData} />;
        case 'mocdata':
          return <MaterialsData inputArray={cellData} />
        default:
          if (typeof cellData === 'object') {
            const stringifiedData = JSON.stringify(cellData);
            return <span>{stringifiedData}</span>;
          }
          return <span>{cellData}</span>;
      }
    };

    
    
    const toggleRowExpansion = async (rowIndex) => {
      const isCurrentlyExpanded = expandedRow === rowIndex;
      if (tableTopRef.current) {
        tableTopRef.current.scrollIntoView({ behavior: 'smooth' });  // Scroll to the top of the table smoothly
      }
      if (!isCurrentlyExpanded) {
        const row = props.data[rowIndex];
        await fetchRowDetails(rowIndex, row.supplierpartnumber, row.supplier);
        if (showConfiguration) {
          setShowConfiguration(false);
          setCurrentConfigRow(null);
          setSelectedButton2(null);
        }
      } else {
        setExpandedRow(null); // Collapse if already expanded
        props.setShowScrollBar(true);
        setShowDetails(false);
      }
    };
    
    const toggleConfigExpansion = (rowIndex) => {
      const row = props.data[rowIndex];
      if (tableTopRef.current) {
        tableTopRef.current.scrollIntoView({ behavior: 'smooth' });  // Scroll to the top of the table smoothly
      }
      
      if (showDetails) {
        setShowDetails(false);
        setExpandedRow(null);
      }
    
      if (currentConfigRow === row) {
        // If the same row is clicked, toggle the configuration visibility
        setShowConfiguration(!showConfiguration);
        props.setShowScrollBar(showConfiguration);
        setSelectedButton2(!showConfiguration ? rowIndex : null); 
      } else {
        // If a different row is clicked, switch to the new row
        setCurrentConfigRow(row);
        setSelectedOption({});
        setSelectedButton(0);
        setSelectedButton2(rowIndex);
        setShowConfiguration(true); // Show the configuration for the new row
        props.setShowScrollBar(false);
      }
    
      setSelectedOptions({});
      setDisabledOptions({});
      setActiveConfigIndex(0); // Reset to the first set of options
    };
    
    const handleButtonClick = (designation, optionDesignation, index) => {
      setActiveConfigIndex(index);
      setSelectedButton(index);
    };
    
    const fetchRowDetails = async (index, supplierPartNumber, supplier) => {
      const url = `${apiUrl}/api/details`;  // Assuming details endpoint
      const payload = { partNumber: supplierPartNumber, supplier: supplier };
      
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'X-CSRFToken': cookies.csrf_token,
          },
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        const data = await response.json();
        setDetailsData(prev => ({ 
          ...prev, 
          [index]: {
            ...data,
            supplierpartnumber: supplierPartNumber
          } 
        }));
        setExpandedRow(index);
        props.setShowScrollBar(false);
        setShowDetails(true);
      } catch (error) {
        console.error("Failed to fetch details", error);
      }
    };
    
    const handleOptionChange = (designation, optionDesignation) => {
      const updatedOptions = { ...selectedOption, [designation]: optionDesignation };

      // Sort the keys of updatedOptions
      const sortedKeys = Object.keys(updatedOptions).sort((a, b) => a.localeCompare(b, undefined, {numeric: true}));

      // Reconstruct the newSelectedOptions object with sorted keys
      const newSelectedOptions = sortedKeys.reduce((obj, key) => {
        obj[key] = updatedOptions[key];
        return obj;
      }, {});

      checkOptionState(designation, optionDesignation, newSelectedOptions)
      setSelectedOption(newSelectedOptions);
      // Auto-progress to the next set of options
      if (activeConfigIndex < currentConfigRow.configurableoptions.length - 1) {
        setActiveConfigIndex((prevIndex) => prevIndex + 1);
        setSelectedButton((prevIndex) => prevIndex + 1);
      } else {
        setSelectedButton(activeConfigIndex);
      }
    };
    
    const isOptionDisabled = (designation, optionDesignation) => {
      const disabledBy = nestedHashmap[designation]?.[optionDesignation]?.["disabledBy"];     
      if (!disabledBy) {
        return false
      }
      for (const [key, value] of Object.entries(disabledBy)) {
        if (Array.isArray(value)) {
          if (value.includes(selectedOption[key])) {
            return true;
          }
        } else {
          if (selectedOption[key] === value) {
            return true;
          }
        }
      }
      return false;
    };
    
    const checkOptionState = (designation, optionDesignation, newSelectedOptions) => {
      for (const [key, value] of Object.entries(newSelectedOptions)) {
        const disabledBy = nestedHashmap[key]?.[value]?.["disabledBy"]; 
        if (disabledBy !== undefined) {
          const isMatch = (subset, superset) => {
            for (let subKey in subset) {
              const subsetValue = subset[subKey];
              const supersetValue = superset[subKey];    
              if (Array.isArray(subsetValue)) {
                if (supersetValue !== undefined && subsetValue.includes(supersetValue)) {
                  return true;
                }
              } else {
                if (subsetValue === supersetValue) {
                  return true;
                }
              }
            }
            return false;
          };
    
          if (isMatch(disabledBy, newSelectedOptions)) {
            delete newSelectedOptions[key];
          }
        }
      }
    }   

    useEffect(() => {
      const buildHashmap = (data) => {
        const map = {};
        data.forEach(item => {
          const key = item.designation;
          map[key] = {};
          item.option.forEach(option => {
            map[key][option.designation] = option;
          });
        });
        return map;
      };
      if (currentConfigRow !== null) {
        const hashmap = buildHashmap(currentConfigRow.configurableoptions);
        setNestedHashmap(hashmap);
      }
      
    }, [currentConfigRow]);

    const closeTable = () => {
      setShowConfiguration(false)
      setShowDetails(false)
      setExpandedRow(null)
      setSelectedButton2(null)
    }

    useEffect(() => {
      console.log(props.data)
    },[props.data])

    return (
        <div className="table-component" ref={tableContainerRef}>
            <div className="tables-container" ref={tableTopRef} >
              {/* Table 1: Fixed Columns */}
              <div className="table-fixed" ref={table1Ref}>
                <table>
                    <tbody>
                      {props.data.map((row, rowIndex) => (
                        <tr key={rowIndex} 
                            ref={rowIndex === props.data.length - 1 ? lastDataElementRef :
                                 rowIndex === 0                     ? firstDataElementRef : 
                                 rowIndex === 18                    ? targetRowRef : 
                                 rowIndex === 10                    ? targetRowRef2 :
                                 null
                        }>
                          <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '400px', minWidth: '400px', paddingTop: "3px", paddingBottom: "3px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                              {/* Left-aligned content */}
                              <div style={{ height: '100px', width: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              {images[row.imagename] ? (
                                <img
                                  src={`data:image/jpeg;base64,${images[row.imagename]}`}
                                  alt="Product"
                                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                                />
                                ) : (
                                  <FontAwesomeIcon 
                                    icon={faImage} 
                                    size="lg" 
                                    style={{"color": "#ddd", width: "100px", height: "100px"}}
                                  />
                                  //<Skeleton style={{ width: '100px', height: '100px' }} />
                                )}
                              </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: '5px' }}>
                              {/* Right-aligned content */}
                              <div>Part Number: {row.supplierpartnumber}</div>
                              <div>Part Name: {row.partname}</div>
                              <div>Supplier: {row.supplier}</div>
                              <div className="flex justify-between items-center">
                              <div style={{display: "flex"}}>
                                
                                <span className="flex-grow" style={{flexDirection: "column", marginRight: "10px"}}>
                                  <button
                                    onClick={() => toggleRowExpansion(rowIndex)}
                                    className={`part-type-button ${expandedRow === rowIndex ? 'selected' : ''}`}
                                  >
                                    More Details
                                  </button>
                                </span>
                                <span style={{}}>
                                {renderCell(row, "mocdata")}
                                </span>
                              </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        
                      ))}
                    </tbody>
                </table>
              </div>
              {/* Table 3: Quantity and Add to Cart */}
              {(
                <div className="table-fixed2" ref={table3Ref}>
                  <table>
                    
                    <tbody>
                      {props.data.map((row, rowIndex) => {
                        const item = cartItems.find(item => item.id === row.supplierpartnumber);

                        return (
                          <tr key={rowIndex}>
                            <td style={{display: 'flex', justifyContent: 'center', height: '113px',borderBottom: '1px solid #ddd'}}>
                              <div style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                                <div style={{display: "flex",justifyContent: 'center', width: '100%'}}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex'}}>
                                      <span style={{ marginRight: '10px', width: "120px", textAlign: 'left' }}>Available Quantity:</span> 
                                      <span 
                                        style={{ textAlign: "left", width: "50px"}}
                                        className={props.changedItems[row.supplierpartnumber] ? 'quantity-changed' : ''}>
                                          {row.quantity}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                
                                {row.configurableoptions.length > 0 ? (
                                  //row.configurableoptions !== null ?
                                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <div style={{display: "flex",justifyContent: 'center', width: '100%'}}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex'}}>
                                          <span style={{ marginRight: '10px', width: "120px", textAlign: 'left' }}>Base Price:</span> 
                                          <span 
                                            style={{ textAlign: "left", width: "50px"}}
                                            className={props.changedItems[row.supplierpartnumber] ? 'quantity-changed' : ''}>
                                              ${row.price}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{display: "flex",justifyContent: 'center', width: '100%'}}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex'}}>
                                          <span style={{ marginRight: '10px', width: "120px", textAlign: 'left' }}>{row.supplierpartnumber} in Cart:</span> 
                                          <span style={{ textAlign: "left", width: "50px"}}>{item ? item.quantity : 0}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{display: "flex",justifyContent: 'center', width: '100%'}}>
                                      <button
                                        style={{ textAlign: 'center', display: "flex", maxWidth: "180px" }}
                                        onClick={() => toggleConfigExpansion(rowIndex)}
                                        className={`part-type-button2 ${selectedButton2 === rowIndex ? 'selected' : ''}`}
                                      >
                                        Configuration 
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div style={{display: "flex",justifyContent: 'center', width: '100%'}}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex'}}>
                                          <span style={{ marginRight: '10px', width: "120px", textAlign: 'left' }}>Price:</span> 
                                          <span style={{ textAlign: "left", width: "50px"}}>${row.price}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <AddToCartButton 
                                      id={row['supplierpartnumber']} 
                                      company={row['supplier']} 
                                      partType={props.partType} 
                                      availableQuantity={row['quantity']} 
                                      imagename={row['imagename']}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {/* Table 2: Overflow Columns */}
              {!showDetails && !showConfiguration && (
                <div className="table-overflow" ref={tableRef}>
                  <table>
                    
                    <tbody ref={rowRef}>
                      {props.data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {showOperatingConditions && (
                            <td>
                              {renderCell(row, "operatingconditions")}
                            </td>
                          )}
                          {showMeasuringData && (
                            <td>
                              {renderCell(row, "measuringdata")}
                            </td>
                          )}
                          {showProcessConnections && (
                            <td>
                              {renderCell(row, "connectiondata")}
                            </td>
                          )}
                          {showElectricalData && (
                            <td>
                              {renderCell(row, "electricaldata")}
                            </td>
                          )}
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {showDetails && !showConfiguration && (
                <div style={{width: "100%", overflowY: "auto"}} className="more-details-table">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div style={{ flex: 1, minWidth: 0, padding: '10px', boxSizing: 'border-box' }}>
                            <div style={{display: 'flex'}}>
                              <div style={{width: '100%'}}>
                                <h3>Product Description:{" "}{detailsData[expandedRow].supplierpartnumber}</h3>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                  <FontAwesomeIcon
                                    className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                                    onClick={() => closeTable()}
                                    style={{ display: 'flex', alignItems: 'center' }} />
                                </button>
                              </div>
                            </div>
                            
                            {detailsData[expandedRow].description ? (
                                <p style={{ wordWrap: 'break-word', overflowWrap: 'break-word', margin: 0, textAlign: 'left' }}>
                                    {detailsData[expandedRow].description}
                                </p>
                            ) : (
                              <div></div>
                              // Assuming Skeleton is a component you have that supports style props
                              //<Skeleton style={{ width: '100%', minWidth: "300px", height: '40px' }} /> // Adjust height as needed to match your design
                            )}
                          </div>
                          <div style={{ flex: 1, padding: '10px', boxSizing: 'border-box' }}>
                            <h3>Technical Documents</h3>
                            <div style={{overflowY: 'auto' }}>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  {detailsData[expandedRow].techfiles && detailsData[expandedRow].techfiles.length > 0 ? (
                                    detailsData[expandedRow].techfiles.map((doc, index) => (
                                      <tr key={index} className="detail-files">
                                        <td className="invoice-link" style={{ minWidth: '100%' }}> 
                                          {doc.name ? (
                                            <a href={`${apiUrl}/api/download/techdoc/${doc.filetoken}`} download>
                                              <i className="fas fa-file-pdf"></i>{doc.name}
                                            </a>
                                          ) : (
                                            <div></div>
                                            // Render Skeleton if doc.name is null
                                            //<Skeleton count={3} style={{ width: '100%', minWidth: "300px", height: '40px' }} />
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <div></div>
                                    //<Skeleton count={3} style={{ width: '100%', minWidth: "300px", height: '40px' }} />
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div style={{ flex: 1, padding: '10px', boxSizing: 'border-box' }}>
                            <h3>CAD Files</h3>
                            <div style={{ overflowY: 'auto' }}>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  {detailsData[expandedRow].cadfiles && detailsData[expandedRow].cadfiles.length > 0 ? (
                                    detailsData[expandedRow].cadfiles.map((file, index) => (
                                      <tr key={index} className="detail-files">
                                        <td className="invoice-link" style={{ minWidth: '100%' }}>
                                          {file.name ? (
                                            <a href={`${apiUrl}/api/download/cadfile/${file.filetoken}`} download>
                                              <i className="fas fa-file-pdf"></i>{file.name}
                                            </a>
                                          ) : (
                                            <div></div>
                                            // Render Skeleton if file.name is null
                                            //<Skeleton count={3} style={{ width: '100%', minWidth: "300px", height: '40px' }} />
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <div></div>
                                    //<Skeleton count={3} style={{ width: '100%', minWidth: "300px", height: '40px' }} />
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}             
              {/* Table 4: More details and docs etc */}
              {showConfiguration && !showDetails && currentConfigRow && (
                <div style={{minHeight: "100%", width: "100%", overflowY: "auto" }} className="more-details-table">
                  <table>
                    <tbody style={{height: "100%"}}>
                      <tr>
                        <td>
                          <div style={{ flex: 1, minWidth: 0, padding: '10px', boxSizing: 'border-box', width: "100%" }}>
                            <div style={{display: 'flex'}}>
                              <div style={{width: '100%'}}>
                                <h3>Current Configuration:{" "}{currentConfigRow.supplierpartnumber}{" "}
                                {Object.entries(selectedOption).map(([designation, optionDesignation], index, array) => (
                                  <span key={designation}>
                                    {optionDesignation}
                                    {index < array.length - 1 ? '-' : ''}
                                  </span>
                                ))}
                                </h3>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                  <FontAwesomeIcon
                                    className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                                    onClick={() => closeTable()}
                                    style={{ display: 'flex', alignItems: 'center' }} />
                                </button>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row"}}>
                              <div style={{width: "200px"}}>
                              <h4 style={{marginTop: "0px", paddingBottom: "2px"}}>Selections</h4>
                                {currentConfigRow.configurableoptions.map((item, index) => (
                                  <div key={item.designation}>
                                    <button
                                      className={`part-type-button config-option-button ${selectedButton === index ? 'selected' : ''} flex justify-between items-center`}
                                      style={selectedOption[item.designation] ? { borderLeft: '4px solid #339AF0', minWidth: "200px"} : { borderLeft: '1px solid #ccc', paddingLeft: "13px", minWidth: "200px"}}
                                      onClick={() => handleButtonClick(item.designation, item.option.designation, index)}
                                    >
                                      <span>
                                        {item.designation} - {item.description}
                                      </span>
                                      <span className="ml-auto">
                                        {selectedOption[item.designation]}
                                      </span>
                                    </button>
                                  </div>
                                ))}
                                <div style={{display: "flex",justifyContent: 'center', width: '100%'}}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex'}}>
                                      <span style={{ marginRight: '10px', width: "120px", textAlign: 'left' }}>Available Quantity:</span> 
                                      <span style={{ textAlign: "left", width: "50px"}}>{currentConfigRow.quantity}</span>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <AddToCartButton 
                                    id={currentConfigRow.supplierpartnumber} 
                                    company={currentConfigRow.supplier} 
                                    partType={props.partType} 
                                    availableQuantity={currentConfigRow.quantity} 
                                    imagename={currentConfigRow.imagename}
                                    configuration={selectedOption}
                                    configurationLength={currentConfigRow.configurableoptions.length}
                                    configurationquantity={currentConfigRow}
                                    setClickedSelectedOption={setSelectedOption}
                                  />
                                </div>
                              </div>
                              <div style={{marginLeft: "10px",width: "100%"}}>
                                <h4 style={{marginTop: "0px"}}>Configuration Options</h4>
                                {currentConfigRow.configurableoptions.map((item, index) => (
                                  <div key={index} style={{}}>
                                    {activeConfigIndex === index && (
                                      <table>
                                        <tbody style={{"border-spacing": "0px"}}>
                                          {item.option.map(option => {
                                            const isDisabled = isOptionDisabled(item.designation, option.designation);
                                            return (
                                              <tr key={option.designation}>
                                                <td>
                                                  <div
                                                    style={{
                                                      padding: "5px 8px",
                                                      border: "solid 1px #ccc",
                                                      borderRadius: "4px",
                                                      backgroundColor: isDisabled ? "#f0f0f0" : "white", // Change the background color if disabled
                                                      cursor: isDisabled ? "default" : "pointer",
                                                      opacity: isDisabled ? 0.5 : 1, // Change opacity if disabled
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      className="hidden-radio"
                                                      id={`${item.designation}-${option.designation}`}
                                                      name={item.designation}
                                                      value={option.designation}
                                                      disabled={isDisabled}
                                                      onChange={() => handleOptionChange(item.designation, option.designation, option.disabledBy)}
                                                      checked={selectedOption[item.designation] === option.designation}
                                                    />
                                                    <label 
                                                      htmlFor={`${item.designation}-${option.designation}`} 
                                                      className="input-radio-label"
                                                      style={{cursor: isDisabled ? "default" : "pointer"}}
                                                    >
                                                      <span 
                                                        className="input-radio"
                                                        style={{cursor: isDisabled ? "default" : "pointer",}}
                                                      ></span>
                                                      <span style={{"fontWeight": 'bold', marginRight: "30px"}}>{option.designation}</span>
                                                      <span>{Array.isArray(option.description) ? option.description.join(", ") : option.description}</span>
                                                      <span className="ml-auto">
                                                        <span style={{"margin-right": "10px"}}>+{option.productiontime} days</span>
                                                        <span>+${option.price}</span>
                                                      </span>
                                                    </label>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
        </div>
    );
    
  }

export default TableComponentTest;

