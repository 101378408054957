import React, { createContext, useContext, useState } from 'react';

const TargetRouteContext = createContext();

export const TargetRouteProvider = ({ children }) => {
  const [targetRoute, setTargetRoute] = useState('/customer');

  return (
    <TargetRouteContext.Provider value={{ targetRoute, setTargetRoute }}>
      {children}
    </TargetRouteContext.Provider>
  );
};

export const useTargetRoute = () => useContext(TargetRouteContext);
