import React, { useState, useEffect, useCallback } from 'react';
import InputWithUnitSelector from '../inputBoxWithSelectorComponent.tsx';
import InputBoxComponent from '../InputBoxComponent.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faMinus, faChevronRight, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useEquipment } from './../body/customerBody/CombinedContext.js';


import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface MeasuringDataFilterProps {
  measuringRangeData: any;
  setMeasuringRangeData: (data: any) => void;
}

const defaultSampleMeasuringData = {
  "pressure": { "label": "Pressure", "selectors": "pressure", "units": "bar" },
};

const MeasuringDataFilter: React.FC<MeasuringDataFilterProps> = ({ measuringRangeData, setMeasuringRangeData }) => {

  const { partType } = useEquipment();
  const { fetchOperatingData, sampleMeasuringDataState, loaded } = useEquipment();

  useEffect(() => { 
    fetchOperatingData(partType, 'measuringdata')
  },[partType])

  const initializeUnitValues = (data: typeof defaultSampleMeasuringData, measuringRangeData: any) => {
    const initialUnitValues: { [key: string]: string | null } = {};
    Object.keys(data).forEach(key => {
      initialUnitValues[key] = measuringRangeData[key]?.units || data[key].units || '';
    });
    return initialUnitValues;
  };
  
  const initializeValues = (data: typeof defaultSampleMeasuringData, measuringRangeData: any) => {
    const initialValues: { [key: string]: string | number } = {};
    Object.keys(data).forEach(key => {
      initialValues[key] = measuringRangeData[key]?.size || '';
    });
    return initialValues;
  };

  const [selection, setSelection] = useState<string>(Object.keys(defaultSampleMeasuringData)[0]);
  const [values, setValues] = useState<{ [key: string]: string | number }>(initializeValues(sampleMeasuringDataState, measuringRangeData));
  const [units, setUnits] = useState<{ [key: string]: string | null }>(initializeUnitValues(sampleMeasuringDataState, measuringRangeData));
  const [fullView, setFullView] = useState<boolean>(false);

  useEffect(() => {
    if (measuringRangeData === 'reset') {
      resetState()
    }
  },[measuringRangeData])

  const resetState = useCallback(() => {
    setValues(initializeValues(sampleMeasuringDataState, {}));
    setUnits(initializeUnitValues(sampleMeasuringDataState, {}));
    setMeasuringRangeData({});
  }, [sampleMeasuringDataState, setMeasuringRangeData]);

  useEffect(() => {
    let updatedMeasuringRangeData: any = {};
  
    Object.keys(sampleMeasuringDataState).forEach(key => {
      if (values[key] !== '') {
        updatedMeasuringRangeData[key] = { size: values[key], units: units[key] };
      }
    });
  
    if (Object.keys(updatedMeasuringRangeData).length === 0) {
      updatedMeasuringRangeData = "";
    }
  
    setMeasuringRangeData(updatedMeasuringRangeData);
  }, [values, units, sampleMeasuringDataState, setMeasuringRangeData]);

  const handleSelectionChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelection(e.target.value);
  }, []);

  const handleValueChange = useCallback((key: string) => (value: string | number) => {
    setValues(prevValues => ({ ...prevValues, [key]: value }));
  }, []);

  const handleUnitChange = useCallback((key: string) => (unit: string | null) => {
    setUnits(prevUnits => ({ ...prevUnits, [key]: unit }));
  }, []);

  const renderInputComponent = useCallback((key: string) => {
    const selectedData = sampleMeasuringDataState[key];
    if (!selectedData) {
      return null
    }
    if (selectedData.selectors) {
      return (
        <InputWithUnitSelector
          value={values[key] || ''}
          setValue={handleValueChange(key)}
          unit={units[key] || ''}
          setUnit={handleUnitChange(key)}
          label={selectedData.label}
          selectors={selectedData.selectors}
        />
      );
    } else {
      return (
        <InputBoxComponent
          string={values[key] || ''}
          setString={handleValueChange(key)}
          label={selectedData.label}
          regexType='string'
        />
      );
    }
  }, [sampleMeasuringDataState, values, units, handleValueChange, handleUnitChange]);

  const toggleView = useCallback(() => {
    setFullView(prevFullView => !prevFullView);
  }, []);

  return (
    <div>
      {!sampleMeasuringDataState || !loaded ? (
        <div>
          <div style={{ fontFamily: 'inherit', fontSize: '14px', marginBottom: '3px', display: "flex", flexDirection: 'row', paddingTop: '2px' }}>
            <div style={{width: "100%"}}>Measuring Range:</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton height={20} width={20} />
            </div>
          </div>
          <div style={{marginBottom: '5px'}}>
            <Skeleton height={18} width={178} />
          </div>
          <div style={{paddingBottom: '5px', marginBottom: '5px'}}>
            <Skeleton height={36} width={178}/>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ fontSize: '14px', marginBottom: '5px', display: "flex", flexDirection: 'row'  }}>
            <div style={{ width: "100%" }}>
              <button 
                onClick={toggleView} 
                style={{
                  border: 'none', 
                  background: 'transparent', 
                  cursor: 'pointer', 
                  padding: 0, 
                  fontFamily: 'inherit', 
                  fontSize: '14px',
                  color: fullView ? '#339AF0' : 'black',
                  fontWeight: fullView ? 'bold': ''
                }}
              >
                Measuring Range:
              </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                <FontAwesomeIcon
                  className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                  onClick={() => resetState()}
                  style={{ display: 'flex', alignItems: 'center' }} />
              </button>
            </div>
          </div>
          <div>
            {fullView ? (
              Object.keys(sampleMeasuringDataState).map(key => (
                <div key={key} style={{ }}>
                  {renderInputComponent(key)}
                </div>
              ))
            ) : (
              <>
                <div>
                  <select
                    value={selection}
                    onChange={handleSelectionChange}
                    style={{ marginBottom: '5px', width: "100%", fontSize: '14px', border: '1px solid #ccc', borderRadius: '4px' }}
                  >
                    {Object.keys(sampleMeasuringDataState).map(key => (
                      <option key={key} value={key}>
                        {sampleMeasuringDataState[key].label}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {renderInputComponent(selection)}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MeasuringDataFilter;
