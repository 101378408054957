// OrdersContext.js
import React, { createContext, useContext, useState, useRef, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useAuth } from './../AuthContext.js';

const OrdersContext = createContext();

export const useOrders = () => useContext(OrdersContext);

export const OrdersProvider = ({ children }) => {
  const { user } = useAuth();
  const [files, setFiles] = useState([]);
  
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  //const [cookies, setCookie] = useCookies(['cart_cookie']);
  const [cookies, setCookie] = useCookies(['cart_cookie', 'csrf_token']); 
  const [loadingOrderAgain, setLoadingOrderAgain] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const apiUrl = process.env.REACT_APP_API_URL;

  const lastOrderElementRef = useCallback(node => {
    if (isLoadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchAdditionalOrders();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoadingMore, hasMore]);

  const reorderItems = async (orderNumber) => {
    setLoadingOrderAgain(prevState => ({ ...prevState, [orderNumber]: true }));

    let currentCart = [];
    if (cookies.cart_cookie) {
      currentCart = typeof cookies.cart_cookie === 'object' ? cookies.cart_cookie : JSON.parse(cookies.cart_cookie);
    }

    try {
      const response = await fetch(`${apiUrl}/api/getCartItems`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify({
          email: user.email,
          orderNumber: orderNumber,
          currentCart: currentCart.length ? currentCart : null
        }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.success === true) {
        const cartItems = data.cart_items;

        cartItems.forEach(item => {
          const existingItemIndex = currentCart.findIndex(ci => ci.id === item.id && ci.company_name === item.company_name && ci.part_type === item.part_type);
          if (existingItemIndex > -1) {
            const existingItem = currentCart[existingItemIndex];
            existingItem.quantity += item.quantity;

            if (existingItem.configurations && item.configurations) {
              item.configurations.forEach(newConfig => {
                const existingConfigIndex = existingItem.configurations.findIndex(ec => JSON.stringify(ec.configuration) === JSON.stringify(newConfig.configuration));
                if (existingConfigIndex > -1) {
                  existingItem.configurations[existingConfigIndex].quantity += newConfig.quantity;
                } else {
                  existingItem.configurations.push(newConfig);
                }
              });
            }
          } else {
            currentCart.push(item);
          }
        });

        setCookie('cart_cookie', JSON.stringify(currentCart), { path: '/' });
      }

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }

    setLoadingOrderAgain(prevState => ({ ...prevState, [orderNumber]: false }));
  };

  const fetchOrders = async (page = currentPage, isInitialLoad = false) => {
    if (isInitialLoad) setIsLoadingInitial(true);
    else setIsLoadingMore(true);

    try {
      const response = await fetch(`${apiUrl}/api/getCustomerOrders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify({
          email: user.email,
          page: page,
          limit: page === 1 ? itemsPerPage : 50
        }),
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const newData = await response.json();
      if (page === 1) {
        setFiles(newData);
      } else {
        setFiles(prevFiles => [...prevFiles, ...newData]);
      }
      setHasMore(newData.length > 0);
      setCurrentPage(page);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      if (isInitialLoad) setIsLoadingInitial(false);
      else setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    if (user && user.email) {
      fetchOrders(1, true);
    }
  }, [user, itemsPerPage]);

  const fetchAdditionalOrders = () => {
    const nextPage = currentPage + 1;
    fetchOrders(nextPage);
  };

  const reloadOrders = () => {
    if (user && user.email) {
      fetchOrders(1, true);
    }
  }

  return (
    <OrdersContext.Provider
      value={{
        files,
        isLoadingInitial,
        isLoadingMore,
        searchTerm,
        setSearchTerm,
        loadingOrderAgain,
        reorderItems,
        lastOrderElementRef,
        hasMore,
        reloadOrders
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
