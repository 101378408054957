import React, {useState,useEffect} from 'react'
import CustomerProfileComponent from './customerProfile/CustomerProfileComponent'
import { useAuth } from './../../AuthContext.js'

function CustomerNavigationComponent() {
    const { user } = useAuth();

    return (
        <div style={{height: 'calc(100% - 75px)', overflow: 'auto'}}>
            <div style={{marginTop: "50px", marginLeft: "auto", marginRight: "auto"}}>
                <CustomerProfileComponent
                    user={user}
                />
            </div>
            
            
        </div>
    )
}

export default CustomerNavigationComponent
