import React, { createContext, useState, useContext } from 'react';

const ImageContext = createContext();

export const useImageContext = () => {
  return useContext(ImageContext);
};

export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState({});

  const addImages = (newImages) => {
    setImages((prevImages) => ({
      ...prevImages,
      ...newImages,
    }));
  };

  return (
    <ImageContext.Provider value={{ images, addImages }}>
      {children}
    </ImageContext.Provider>
  );
};
