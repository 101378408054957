import React, { useEffect, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import './css/LandingHeaderComponent.css';
import './css/GoogleLoginButton.css'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext.js';
import  { AlertContext } from './../body/customerBody/AlertContext'
const apiUrl = process.env.REACT_APP_API_URL;

function LoginButton() {
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const { showAlertMessage } = useContext(AlertContext);

    useEffect(() => {
        const loadGoogleScript = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.onload = () => initGoogleSignIn();
            document.body.appendChild(script);
        };

        const initGoogleSignIn = () => {
            window.google.accounts.id.initialize({
                client_id: "866596646199-rivlfhgfcbrh3ps9jfa0jin3d1ihleqc.apps.googleusercontent.com",
                callback: handleCredentialResponse,
            });
            window.google.accounts.id.renderButton(
                document.getElementById('google-signin-button'),
                {size: 'large', text: 'continue_with' }
            );
        };

        if (!window.google) {
            loadGoogleScript();
        } else {
            initGoogleSignIn();
        }
    }, []);

    
    const handleCredentialResponse = async (response) => {
        const userData = jwtDecode(response.credential);
        if (userData.email_verified) {
            try {
                const csrfTokenResponse = await fetch(`${apiUrl}/api/csrf-token`, {
                    credentials: 'include',
                });
                if (!csrfTokenResponse.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }
                const csrfTokenData = await csrfTokenResponse.json();
                const csrfToken = csrfTokenData.csrf_token;
                const GoogleValidationResponse = await fetch(`${apiUrl}/api/google-signin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken,
                    },
                    body: JSON.stringify({ credential: response.credential }),
                    credentials: 'include',
                });
                
                const testData = await GoogleValidationResponse.json()
                if (testData.success) {
                    const validationResponse = await fetch(`${apiUrl}/api/userValidation`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrfToken,
                        },
                        body: JSON.stringify({ email: userData.email }),
                        credentials: 'include',
                    });
        
                    const data = await validationResponse.json();
                    if (data.success) {
                        setUser(prevUser => ({
                            ...prevUser,
                            userType: data.user_type,
                            email: data.email,
                            company: data.company,
                            given_name: data.given_name,
                            family_name: data.family_name,
                            contact_number: data.contact_number,
                            abn: data.abn,
                            accounts_email: data.accounts_email,
                            previous_delivery: data.previous_delivery,
                            previous_billing: data.previous_billing
                        }));
                        
                        navigate('/customer');
                    } else {
                        setUser(prevUser => ({
                            ...prevUser,
                            email: userData.email,
                            userType: 'sign up',
                        }));
                        
                    }
                } else {
                    showAlertMessage('warning', 'You are not authorized to view this page')
                    console.error("You are not authorized to view this page");
                }
            } catch (error) {
                console.error("Failed to authenticate", error);
            }
        } else {
            console.error("Failed to authenticate");
        }
    };


    return (
        <div >
            <div className="google-signin-container">
            <div id="google-signin-button"></div>

            </div>
        </div>
    );
}

export default LoginButton;










