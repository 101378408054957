import React, { useEffect } from 'react'
import { useFormData } from './context/FormDataContext.js'
import { useGoogleMaps } from './hook/useGoogleMaps.js'
import { useAuth } from '../AuthContext.js'
import InputBoxComponent from '../InputBoxComponent.tsx';


function BillingInformationComponent() {
    const {user} = useAuth()
    const {formData, formData2, updateFormData2, showBillingDetails, setShowBillingDetails, handleUseBillingClick } = useFormData()
    

    const { initAutocomplete, isLoaded } = useGoogleMaps('AIzaSyBKrYgDlQdiuin7l4VFs5WEU6nz0mRQywc', {
        fields: ['address_components', 'geometry', 'name'],
        types: ['address'],
        componentRestrictions: { country: ['AU'] }
    });
 
    useEffect(() => {
        if (isLoaded && document.getElementById('Address Line 1')) {
            initAutocomplete( 'billing-details', 'Address Line 1',
            {
                fields: ['address_components', 'geometry', 'name'],
                types: ['address'],
                componentRestrictions: { country: ['AU'] }
            }, place => {}
        )};
    }, [initAutocomplete, isLoaded]);

    return (
        <div>
            <h2>Confirmed Details</h2>
            <div>
                <div className="form-group">
                    <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                        <div className="form-group-wrapper">
                            <div className="form-group location-group" style={{"width":"100%"}}>
                                <input 
                                    style={{ backgroundColor: "#f0f0f0"}} 
                                    type="text" id="email" 
                                    placeholder=" " 
                                    defaultValue={user.email} 
                                    disabled>
                                </input>
                                <label htmlFor="email">Email Address</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                        <div className="form-group-wrapper">
                            <div className="form-group location-group" style={{"width":"100%"}}>
                                <input style={{ backgroundColor: "#f0f0f0"}} type="text" id="Address Details" placeholder=" " defaultValue={`${formData.location} ${formData.locality} ${formData.administrative_area_level_1} ${formData.postal_code} ${formData.country}`} disabled></input>
                                <label htmlFor="email">Delivery Address</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2>Billing Details</h2>

            <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc' }}>
                
                <div style={{borderBottom: "solid 1px #ccc", padding: "10px 8px"}}>
                    <input
                        id="same-address"
                        type="radio"
                        name="billingAddress"
                        className="hidden-radio"
                        checked={!showBillingDetails}
                        onChange={() => setShowBillingDetails(false)}
                    />
                    <label htmlFor="same-address" className="input-radio-label">
                        <span className="input-radio"></span>
                        Same as shipping address
                    </label>
                </div>
                <div style={{padding: "10px 8px"}}> 
                    <input
                        id="different-address"
                        type="radio"
                        name="billingAddress"
                        className="hidden-radio"
                        checked={showBillingDetails}
                        onChange={() => setShowBillingDetails(true)}
                    />
                    <label htmlFor="different-address" className="input-radio-label">
                        <span className="input-radio"></span>
                        Use a different shipping address
                    </label>
                </div>
            </div>

            {showBillingDetails && (
                
                <div className="delivery-details" style={{"width": "100%", "marginRight":"10px"}}>
                    
                    <div className="delivery-details-wrapper">
                        {user && user.previous_billing !== null && (
                            <div className="form-group">
                                <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                                    <div className="form-group location-group-confirm" style={{"width":"100%"}}>
                                        <div className="form-group-wrapper-confirm">
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <input 
                                                    style={{ backgroundColor: "#f0f0f0"}} 
                                                    type="text" 
                                                    id="Delivery Address Details" 
                                                    placeholder=" " 
                                                    defaultValue={`${user.previous_billing.address.line2} ${user.previous_billing.address.line1} ${user.previous_billing.address.city}, ${user.previous_billing.address.state} ${user.previous_billing.address.postal_code}, ${user.previous_billing.address.country}`}
                                                    disabled>
                                                </input>
                                                <label htmlFor="email">Previous Billing Address</label>
                                                <button onClick={() => handleUseBillingClick()} className="change-btn">Use Address</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="location-container" style={{"display":"flex", "gap": "10px"}}>
                            <div className="form-group-wrapper">
                                <InputBoxComponent
                                  string={formData2.given_name}
                                  setString={(e) => {updateFormData2({ ...formData2, given_name: e })}}
                                  label={'First Name'}
                                  regexType='string'
                                  defaultValue={user.given_name}
                                />
                            </div>
                            <div className="form-group-wrapper">
                                <InputBoxComponent
                                  string={formData2.family_name}
                                  setString={(e) => {updateFormData2({ ...formData2, family_name: e })}}
                                  label={'Last Name'}
                                  regexType='string'
                                  defaultValue={user.family_name}
                                />
                            </div>   
                        </div>
                        
                        <div>
                            <div className="form-group-wrapper">
                                <InputBoxComponent
                                  string={formData2.company}
                                  setString={(e) => {updateFormData2({ ...formData2, company: e })}}
                                  label={'Company'}
                                  regexType='string'
                                  defaultValue={user.company}
                                />
                            </div>
                        </div>
                        
                            <div className="location-container" style={{"width":"100%"}}>
                                <div className="form-group-wrapper">
                                    <InputBoxComponent
                                      string={formData2.location}
                                      setString={(e) => {updateFormData2({ ...formData2, location: e })}}
                                      label={'Address Line 1'}
                                      regexType='address'
                                    />
                                </div>
                            </div>
                        
                        
                            <div className="location-container">
                                <div className="form-group-wrapper">
                                    <InputBoxComponent
                                      string={formData2.suite}
                                      setString={(e) => {updateFormData2({ ...formData2, suite: e })}}
                                      label={'Address Line 2'}
                                      regexType='address'
                                    />
                                </div>
                            </div>
                       
                            <div className="form-group-wrapper">
                            <div className="location-container" style={{"display":"flex", "gap": "10px", minWidth: '100%'}}>
                                <div style={{width: "100%"}}>
                                    <InputBoxComponent
                                      string={formData2.locality}
                                      setString={(e) => {updateFormData2({ ...formData2, locality: e })}}
                                      label={'City'}
                                      regexType='string'
                                    />
                                </div>
                                <div style={{width: "100%"}}>
                                <InputBoxComponent
                                  string={formData2.administrative_area_level_1}
                                  setString={(e) => {updateFormData2({ ...formData, administrative_area_level_1: e })}}
                                  label={'State'}
                                  regexType='string'
                                />
                                </div>
                                <div style={{width: "100%"}}>
                                <InputBoxComponent
                                  string={formData2.postal_code}
                                  setString={(e) => {updateFormData2({ ...formData2, postal_code: e })}}
                                  label={'Postal Code'}
                                  regexType='number'
                                />
                                </div>
                            </div>
                            </div>
                            <div className="form-group-wrapper">
                                <InputBoxComponent
                                  string={formData2.country}
                                  setString={(e) => {updateFormData2({ ...formData2, country: e })}}
                                  label={'Country'}
                                  regexType='string'
                                />
                            </div>
                        
                            <div className="form-group-wrapper">
                                <div style={{"display":"flex", "gap": "10px", minWidth: '100%'}}>
                                    <div style={{width: '120px'}}>
                                        <InputBoxComponent
                                          string={'+61'}
                                          defaultValue={'+61'}
                                          setString={''}
                                          disabled={true}
                                          label={'Country Code'}
                                          regexType='string'
                                        />
                                    </div>
                                    <div style={{width: '100%'}}>
                                        <InputBoxComponent
                                          string={formData2.contact_number}
                                          setString={(e) => {updateFormData2({ ...formData2, contact_number: e })}}
                                          label={'Contact Number'}
                                          regexType='number'
                                          defaultValue={user.contact_number}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )}

            

                <input id="billing-location-input" className="visually-hidden" disabled></input>
        </div>
    )
}

export default BillingInformationComponent