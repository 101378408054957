import React, { useState, useEffect, useRef } from 'react';
import './css/PartTypeSelectorComponent.css';
import { useEquipment } from './CombinedContext'; // Import the custom hook
const apiUrl = process.env.REACT_APP_API_URL;

function PartTypeSelectorComponent({ }) {
    //const [partTypes, setPartTypes] = useState([]);
    //const [selectedPartType, setSelectedPartType] = useState('pressuretransducers');
    const [searchTerm, setSearchTerm] = useState('');
    const { partTypes, setPartTypes, partType, setPartType} = useEquipment();

    const filterComponentRef = useRef(null);
    const filterWrapperRef = useRef(null);
    const filterButtonRef = useRef(null);

    useEffect(() => {
        // Fetch part types when component mounts
        const fetchPartTypes = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/getPartTypes`);
                const data = await response.json();
                setPartTypes(data);
            } catch (error) {
                console.error('Error fetching part types:', error);
            }
        };
        if (partTypes.length === 0) {
            fetchPartTypes();
        }
    }, []);

    useEffect(() => { 
        // Check for overflow and add padding if necessary
        const checkOverflow = () => {
            if (filterWrapperRef.current.scrollHeight > filterWrapperRef.current.clientHeight) {
                filterComponentRef.current.style.width = '230px';
                filterWrapperRef.current.style.paddingRight = '10px';
                filterComponentRef.current.style.paddingRight = '0px';
                filterButtonRef.current.style.paddingRight = '27px'
              } else {
                filterComponentRef.current.style.width = '213px';
                filterComponentRef.current.style.paddingRight = '10px';
                filterWrapperRef.current.style.paddingRight = '0px';
                filterButtonRef.current.style.paddingRight = '0px'
              }
        };
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        const observer = new MutationObserver(checkOverflow);
        observer.observe(filterWrapperRef.current, { childList: true, subtree: true });
        return () => {
            window.removeEventListener('resize', checkOverflow);
            observer.disconnect();
        };
    }, [partTypes]);

    // Handle clicking on a part type button
    const handlePartTypeClick = (partTypeKey) => {
        //setSelectedPartType(partTypeKey);
        setPartType(partTypeKey);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredPartTypes = (partTypes ?? []).filter(({ name }) =>
        name.toLowerCase().includes(searchTerm)
    );
    

    return (
        <div className="part-type-selector-container" ref={filterComponentRef}>
            <div className="part-type-search-bar-container" ref={filterButtonRef}>
                <input
                    className="part-type-search-bar"
                    type="search"
                    placeholder="Search part types..."
                    onChange={handleSearchChange}
                />
            </div>
            <div className="part-type-button-container" ref={filterWrapperRef}>
                {filteredPartTypes.map(({ key, name }) => (
                    <button
                        className={`part-type-button ${partType === key ? 'selected' : ''}`}
                        key={key}
                        onClick={() => handlePartTypeClick(key)}
                    >
                        <span className="radio-button"></span>
                        <span className="button-text">{name}</span>
                    </button>
                ))}
            </div>
        </div>
    );
}

export default PartTypeSelectorComponent;
