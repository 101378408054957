import React, { createContext, useState, useContext } from 'react';

const CheckoutImageContext = createContext();

export const useCheckoutImageContext = () => {
  return useContext(CheckoutImageContext);
};

export const CheckoutImageProvider = ({ children }) => {
  const [images, setImages] = useState({});

  const addImages = (newImages) => {
    setImages((prevImages) => ({
      ...prevImages,
      ...newImages,
    }));
  };

  

  return (
    <CheckoutImageContext.Provider value={{ images, addImages }}>
      {children}
    </CheckoutImageContext.Provider>
  );
};
