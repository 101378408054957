import { useEffect, useRef } from 'react';
import './css/FilterComponent.css';
import InputBoxComponent from '../../InputBoxComponent.tsx';
import ConnectionDataFilter from '../../filters/ConnectionDataFilter.tsx';
import OperatingConditionsFilter from '../../filters/OperatingConditionsFilter.tsx';
import MeasuringDataFilter from '../../filters/MeasuringDataFilter.tsx';
import { useEquipment } from './CombinedContext';
 

function FilterComponent(props) {
  const { databaseHeaders, partType } = useEquipment();
  const {
    companyName, setCompanyName,
    partName, setPartName,
    partNumber, setPartNumber,
    connectionData, setConnectionData,
    operatingConditions, setOperatingConditions,
    measuringRangeData, setMeasuringRangeData,
    mocData, setMocData,
    filterData
  } = useEquipment();

  const filterComponentRef = useRef(null);
  const filterWrapperRef = useRef(null);
  const filterButtonRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (filterWrapperRef.current.scrollHeight > filterWrapperRef.current.clientHeight) {
        filterComponentRef.current.style.width = '217px';
        filterWrapperRef.current.style.paddingRight = '10px';
        filterComponentRef.current.style.paddingRight = '0px';
        filterButtonRef.current.style.paddingRight = '27px'
      } else {
        filterComponentRef.current.style.width = '200px';
        filterComponentRef.current.style.paddingRight = '10px';
        filterWrapperRef.current.style.paddingRight = '0px';
        filterButtonRef.current.style.paddingRight = '0px'
      }
    };

    // Initial check
    checkOverflow();

    // Check on window resize
    window.addEventListener('resize', checkOverflow);

    // Optional: Check when content changes dynamically
    const observer = new MutationObserver(checkOverflow);
    observer.observe(filterWrapperRef.current, { childList: true, subtree: true });

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkOverflow);
      observer.disconnect();
    };
  }, []);
  

  const renderInput = (filter) => {
    switch (filter) {
      case 'supplierpartnumber':
        return (
          <InputBoxComponent
            string={partNumber}
            setString={setPartNumber}
            label={'Part Number'}
            regexType='string'
          />
        );
      case 'partname':
        return (
          <InputBoxComponent
            string={partName}
            setString={setPartName}
            label={'Part Name'}
            regexType='string'
          />
        );
      case 'supplier':
        return (
          <InputBoxComponent
            string={companyName}
            setString={setCompanyName}
            label={'Supplier'}
            regexType='string'
          />
        );
      case 'mocdata':
        return (
          <div>
            <div style={{ fontSize: '14px', marginBottom: '5px' }}>Materials of Construction:</div>
            <InputBoxComponent
              string={mocData}
              setString={setMocData}
              label={'Materials of Construction'}
              regexType='string'
            />
          </div>
        );
      case 'operatingconditions':
        return (
          <OperatingConditionsFilter
            operatingConditionsData={operatingConditions}
            setOperatingConditionsData={setOperatingConditions}
          />
        );
      case 'connectiondata':
        return (
          <ConnectionDataFilter
            connectionData={connectionData}
            setConnectionData={setConnectionData}
          />
        );
      case 'measuringdata':
        return (
          <MeasuringDataFilter
            measuringRangeData={measuringRangeData}
            setMeasuringRangeData={setMeasuringRangeData}
          />
        );
      default:
        return null;
    }
  };

  const predefinedOrder = ['supplierpartnumber', 'partname', 'supplier', 'mocdata', 'operatingconditions', 'connectiondata', 'measuringdata'];

  const renderInputs = (filters) => {
    // Sort filters based on the predefined order
    const sortedFilters = filters.sort((a, b) => predefinedOrder.indexOf(a) - predefinedOrder.indexOf(b));

    return sortedFilters.map(filter => (
      <div key={filter}>
        {renderInput(filter)}
      </div>
    ));
  };

  const resetFilters = () => {
    setCompanyName('');
    setPartName('');
    setPartNumber('');
    setMocData('');
    setOperatingConditions('reset');
    setConnectionData('reset');
    setMeasuringRangeData('reset');
  };

  return (
    <div className="filter-component" ref={filterComponentRef}>
      <div className="filter-wrapper" ref={filterWrapperRef} >
        <div style={{flexDirection: 'row', display: 'flex'}}>
            <div style={{ fontSize: '14px', marginBottom: '5px', flexGrow: 1 }}>Filters</div>
            <button
                  style={{fontSize: '14px',background: "transparent", border: 'none', cursor: 'pointer', marginBottom: '5px', paddingRight: '0px'}}
                  className="hover:text-gray-500"
                  onClick={resetFilters}
                >
                  Clear All
                </button>
        </div>
        {renderInputs(databaseHeaders)}
      </div>
      <div id="filter-button-wrapper" ref={filterButtonRef}>
        <input
          type="submit"
          id="filter-button"
          value="Filter"
          onClick={() => filterData(partType, 1, false)}
        />
      </div>
    </div>
  );
}

export default FilterComponent;