function OperatingConditions({ inputArray }) {
    // Check if inputArray is an object and has the expected structure
    if (inputArray && typeof inputArray === 'object' && 
        inputArray.hasOwnProperty('operatingtemperature') && 
        inputArray.hasOwnProperty('operatingpressure')) {
            const operatingTemperature = inputArray.operatingtemperature
            const operatingPressure = inputArray.operatingpressure
            const burstingpressure = inputArray.hasOwnProperty('burstingpressure') ? inputArray.burstingpressure : null;
            const protection = inputArray.hasOwnProperty('protection') ? inputArray.protection : null;
            const processmedium = inputArray.hasOwnProperty('processmedium') ? inputArray.processmedium : null;

        // Correctly format and return as a string
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '10px', width: '110px', textAlign: 'left' }}>Temperature:</span>
                  <span>{`${operatingTemperature.min} - ${operatingTemperature.max} ${operatingTemperature.units}`}</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '10px', width: '110px', textAlign: 'left' }}>Pressure:</span>
                  <span>{`${operatingPressure.min} - ${operatingPressure.max} ${operatingPressure.units}`}</span>
                </div>
                {burstingpressure && (
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '10px', width: '110px', textAlign: 'left' }}>Burst Pressure:</span>
                    <span>{burstingpressure.value} {burstingpressure.units}</span>
                  </div>
                )}
                {protection && protection.length > 0 && (
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '10px', width: '110px', textAlign: 'left' }}>Protection:</span>
                    <span>
                      {protection.length > 1
                        ? `IP ${Math.min(...protection)} - ${Math.max(...protection)}`
                        : `IP ${protection[0]}`}
                    </span>
                  </div>
                )}
                {processmedium && (
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '10px', width: '110px', textAlign: 'left' }}>Process Medium:</span>
                    <span>
                      {processmedium.liquid && processmedium.gas
                        ? 'Liquid, Gas'
                        : processmedium.liquid
                        ? 'Liquid'
                        : processmedium.gas
                        ? 'Gas'
                        : ''}
                    </span>
                  </div>
                )}
              </div>
            </div>
        );
    }

    // If inputArray does not match the expected format,
    // Check if it's a simple type (string or number) and return it directly,
    // or return null if it's none of those.
    return typeof inputArray === 'string' || typeof inputArray === 'number' 
        ? <span>{inputArray}</span> 
        : null; 
}

export default OperatingConditions;

