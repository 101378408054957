import React, { createContext, useContext, useState } from 'react';

const PurchaseOrderMandateContext = createContext();

export const useMandate = () => useContext(PurchaseOrderMandateContext);

export const PurchaseOrderMandateProvider = ({ children }) => {
    const [purchaseOrderMandate, setPurchaseOrderMandate] = useState(false);

    return (
        <PurchaseOrderMandateContext.Provider value={{ purchaseOrderMandate, setPurchaseOrderMandate }}>
            {children}
        </PurchaseOrderMandateContext.Provider>
    );
};
