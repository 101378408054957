import React, { createContext, useState } from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    const showAlertMessage = (type, message) => {
        const newAlert = { id: Date.now(), type, message };
        setAlerts(prevAlerts => [...prevAlerts, newAlert]);
    };

    const removeAlert = (id) => {
        setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
    };

    const clearAlerts = () => {
        setAlerts([]);
    };

    return (
        <AlertContext.Provider value={{ alerts, showAlertMessage, removeAlert, clearAlerts }}>
            {children}
        </AlertContext.Provider>
    );
};
