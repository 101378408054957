import React, {useState, useEffect, useContext} from 'react'
import './css/customerProfile.css'
import { PulseLoader } from 'react-spinners';
import { useAuth } from './../../../AuthContext.js'

import { AlertContext } from "./../../../body/customerBody/AlertContext"

import InputBoxComponent from './../../../InputBoxComponent.tsx';

import { useCookies } from 'react-cookie';

import useIdleActivity from './../../../useIdleActivity'; // Import the custom hook
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

function CustomerProfileComponent() {
    const {user, setUser} = useAuth()
    const navigate = useNavigate()
    const handleLogout = () => {
        setUser(null);
        navigate('/login')
        // Optionally, you can also remove cookies or perform other logout actions here
    };

    useIdleActivity(handleLogout);

    const [cookies] = useCookies(['csrf_token']); 
    const [formData, setFormData] = useState({
        given_name: user.given_name,
        family_name: user.family_name,
        email: user.email,
        contact_number: user.contact_number,
        company: user.company,
        abn: user.abn,
        accounts_email: user.accounts_email,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [, setIsEditable] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const { showAlertMessage } = useContext(AlertContext);

    useEffect(() => {
        // This effect updates formData anytime the user changes.
        setFormData({
            given_name: user.given_name,
            family_name: user.family_name,
            email: user.email,
            contact_number: user.contact_number,
            company: user.company,
            abn: user.abn,
            accounts_email: user.accounts_email
        });
    }, [user]);

    useEffect(() => {
        // Check if any form data is different from the initial user data.
        const dataChanged = Object.keys(formData).some(key => formData[key] !== user[key]);
        setIsChanged(dataChanged);
    }, [formData, user]);

    const validateProfile = () => {
        let errors = [];

        if (!/^[A-Za-z]+$/.test(formData.given_name)) {
            errors.push("First name can only contain letters.");
        }
        if (!/^[A-Za-z]+$/.test(formData.family_name)) {
            errors.push("Last name can only contain letters.");
        }
        if (!/^[A-Za-z0-9 ]+$/.test(formData.company)) {
            errors.push("Company name can only contain letters, numbers, and spaces.");
        }
        if (!/^04\d{8}$/.test(formData.contact_number)) {
            errors.push("Phone number must be a valid Australian number starting with 04 followed by 8 digits.");
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(formData.email)) {
            errors.push("Email address is not valid.");
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(formData.accounts_email)) {
            errors.push("Accounts email address is not valid.");
        }
        if (!/^[0-9]{11}$/.test(formData.abn)) {
            errors.push("Invalid ABN. It should be 11 digits")
        }

        if (errors.length > 0) {
            showAlertMessage('warning', `${errors.join('\n ')}`)
            //alert(`Please correct the following errors:\n- ${errors.join('\n- ')}`);
            return false;
        }

        return true;
    };

    const saveDetails = async () => {
        if (!validateProfile()) {
            return; // Stop the save operation if validation fails
        }
        setIsLoading(true)
        setIsEditable(true);
        try {
            const response = await fetch(`${apiUrl}/api/saveProfileDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token, 
                },
                body: JSON.stringify({
                    formData: formData
                }),
                credentials: 'include',
            });
            if (response.ok) {
                setUser(prevUser => ({
                    ...prevUser,
                    ...formData // Assuming response sends back the updated user data
                }));
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
                setIsEditable(false);
            }, 1000); // 1000 milliseconds = 1 second
        }
    };

    return (
        <div className="ProfileEntryPoint">
            <div className="content" data-content aria-hidden="false">
                <div className="profile-wrap" style={{marginBottom: "50px"}}>
                    <div className="customer-information" style={{ "width": "100%", "gap":"10px"}}>
                        <div className ='contact-details' style={{"width": "100%"}}>
                            <h2>Account Settings</h2>
                                <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                                    <div className="form-group-wrapper">
                                        <div className="form-group location-group" style={{"width":"100%"}}>
                                            <InputBoxComponent
                                              string={user.email}
                                              setString=''
                                              label={'Email Address'}
                                              //defaultValue={user.email}
                                              disabled={true}
                                              regexType='email'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="location-container" style={{"display":"flex", "gap": "10px"}}>
                                    <div className="form-group-wrapper">
                                        <div className="form-group location-group" style={{"minWidth": "100%"}}>
                                            <InputBoxComponent
                                              string={formData.given_name}
                                              setString={(e) => {setFormData({ ...formData, given_name: e })}}
                                              label={'First Name'}
                                              regexType='string'
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group-wrapper">
                                        <div className="form-group location-group" style={{"minWidth": "100%"}}>
                                            <InputBoxComponent
                                              string={formData.family_name}
                                              setString={(e) => {setFormData({ ...formData, family_name: e })}}
                                              label={'Last Name'}
                                              regexType='string'
                                            />
                                        </div>
                                    </div>   
                                </div>
                                <div className="form-group-wrapper">
                                    <div style={{display: 'flex', gap: "10px"}}>
                                        <div style={{"width":"50%"}}>
                                            <InputBoxComponent
                                              string={formData.company}
                                              setString={(e) => {setFormData({ ...formData, company: e })}}
                                              label={'Company'}
                                              regexType='string'
                                            />
                                        </div>
                                        <div style={{"width":"50%"}}>
                                            <InputBoxComponent
                                              string={formData.abn}
                                              setString={(e) => {setFormData({ ...formData, abn: e })}}
                                              label={'ABN'}
                                              regexType='number'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group-wrapper">
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <div  style={{width: '100px'}}>
                                            <InputBoxComponent
                                              string={'+61'}
                                              setString={''}
                                              label={'Country Code'}
                                              regexType='string'
                                              disabled={true}
                                            />
                                        </div>
                                        <div  style={{flexGrow: 1}}>
                                            <InputBoxComponent
                                              string={formData.contact_number}
                                              setString={(e) => {setFormData({ ...formData, contact_number: e })}}
                                              label={'Contact Number'}
                                              regexType='number'
                                            />
                                        </div>
                                    </div>
                                </div>
                            <div className="location-container">
                                <div className="form-group-wrapper">
                                    <div  style={{"minWidth": "100%"}}>
                                        <InputBoxComponent
                                          string={formData.accounts_email}
                                          setString={(e) => {setFormData({ ...formData, accounts_email: e })}}
                                          label={'Accounts Email'}
                                          regexType='email'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="profile-save-button" onClick={saveDetails} disabled={isLoading || !isChanged}>
                                {isLoading ? (
                                        <PulseLoader size={5} color={"#FFFFFF"} />
                                ) : (
                                    "Update details"
                                )}
                            </button>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
    );
}

export default CustomerProfileComponent
