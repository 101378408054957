import React, { createContext, useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useAuth } from './../../AuthContext.js'
import { AlertContext } from "./AlertContext"
const EquipmentContext = createContext();

export const EquipmentProvider = ({ children }) => {
  const [partTypes, setPartTypes] = useState([])
  const [partType, setPartType] = useState('pressuretransducers');
  const [cookies] = useCookies(['cart_cookie', 'csrf_token']); 
  const [headers, setHeaders] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [databaseHeaders, setDatabaseHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { showAlertMessage } = useContext(AlertContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedButton2, setSelectedButton2] = useState(null)

  const [expandedRow, setExpandedRow] = useState(null)

  const { setUser } = useAuth();

  const apiUrl = process.env.REACT_APP_API_URL;

  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDatabaseOffset, setcurrentDatabaseOffset] = useState(0)
  const [, setPreviousDatabaseOffset] = useState(0)

  const [offsetMap, setOffsetMap] = useState({
    1: [0, 0, 0] // Initial page with initial offsets
  });

  const [companyName, setCompanyName] = useState("");
  const [partName, setPartName] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [mocData, setMocData] = useState("")
  const [connectionData, setConnectionData] = useState("");
  const [operatingConditions, setOperatingConditions] = useState("");
  const [measuringRangeData, setMeasuringRangeData] = useState("")

  const filterStateMap = {
    company: companyName,
    part_name: partName,
    part_number_supplier: partNumber,
    connection_data: connectionData,
    operating_conditions_data: operatingConditions,
    measuringrange_data: measuringRangeData,
    moc_data: mocData,
  };
  
  // Use useEffect to watch for changes in filterStateMap and reset offsetMap
  useEffect(() => {
    setOffsetMap({
      1: [0, 0, 0]
    });
  }, [companyName, partName, partNumber, mocData, connectionData, operatingConditions, measuringRangeData]);

  const [itemsPerPage] = useState(30);
  const [hasMore, setHasMore] = useState(true);
  const [hasLess, setHasLess] = useState(false);
  const observer = useRef();
  const observer2 = useRef();

  const tableContainerRef = useRef(null);
  const prevScrollHeightRef = useRef(0);
  const prevScrollTopRef = useRef(0);
  const tableTopRef = useRef(null)
  const targetRowRef = useRef(null)
  const targetRowRef2 = useRef(null)
  const headersRef = useRef(null)

  const [showDetails, setShowDetails] = useState(false)
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [currentConfigRow, setCurrentConfigRow] = useState(null);
  const [dbEnd, setDbEnd] = useState(false)
  const [filters, setFilters] = useState(false)
  const [isOffsetMapUpdated, setIsOffsetMapUpdated] = useState(false);
  
  useEffect(() => {
    setFilters(false);
    setCurrentPage(1)
    setOffsetMap({
      1: [0, 0, 0]
    });
    setShowConfiguration(false)
    setShowDetails(false)
    setSelectedButton(0)
    setSelectedButton2(null)
    setExpandedRow(null)
    setIsOffsetMapUpdated(true);
  }, [partType]);

  useEffect(() => {
    if (isOffsetMapUpdated) {
      fetchHeaders(partType);
      fetchData(partType);
      setIsOffsetMapUpdated(false); // Reset the flag
    }
  }, [isOffsetMapUpdated, partType]);

  const defaultSampleOpData = {
    "operating_pressure": {"label": "Pressure", "selectors": "pressure", "units": "bar" },
  };
  const defaultSampleMeasuringData = {
    "pressure": { "label": "Pressure", "selectors": "pressure", "units": "bar" },
  }
  const defaultSampleConnectionData = {
    "connection1": { "label": "Connection 1", "selectors": "processconnections", "units": "DN" },
  }

  const [sampleOpDataState, setSampleOpDataState] = useState(defaultSampleOpData);
  const [sampleMeasuringDataState, setSampleMeasuringDataState] = useState(defaultSampleMeasuringData)
  const [sampleConnectionDataState, setSampleConnetionDataState] = useState(defaultSampleConnectionData)
  
  const [loaded, setLoaded] = useState(false);
  const [filtering] = useState(false)
  const [shouldFetchData, setShouldFetchData] = useState(false);
  
  useEffect(() => {
    if (shouldFetchData) {
      setShowDetails(false)
      setShowConfiguration(false)
      setSelectedButton(0)
      setSelectedButton2(null)
      setExpandedRow(null)
      fetchData(partType, 1, false);
      setShouldFetchData(false); // Reset the fetch signal
    }
  }, [shouldFetchData]);

  const filterData = () => {
    setOffsetMap({ 1: [0, 0, 0] });
    setShouldFetchData(true);
  };

  const storeScrollPosition = () => {
    if (tableContainerRef.current) {
      prevScrollHeightRef.current = tableContainerRef.current.scrollHeight;
      prevScrollTopRef.current = tableContainerRef.current.scrollTop;
    }
  };

  const adjustScrollPositionDown = () => {
    if (tableContainerRef.current && targetRowRef.current) {
      const targetRowOffsetTop = targetRowRef.current.offsetTop;
      const targetRowHeight = targetRowRef.current.clientHeight;
      const containerHeight = tableContainerRef.current.clientHeight;
      const scrollToPosition = targetRowOffsetTop - (containerHeight - targetRowHeight);
      tableContainerRef.current.scrollTop = scrollToPosition;
    }
  };

  const adjustScrollPositionUp = () => {
    if (tableContainerRef.current && targetRowRef2.current) {
      const targetRowOffsetTop = targetRowRef2.current.offsetTop;
      tableContainerRef.current.scrollTop = targetRowOffsetTop;
    }
  }

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  },[])

  const lastDataElementRef = useCallback(node => {
    if (isLoadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !showConfiguration && !showDetails && !dbEnd) {
        setTimeout(() => {
          fetchAdditionalOrders();
        }, 200);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoadingMore, hasMore, showConfiguration, showDetails, dbEnd]);

  const firstDataElementRef = useCallback(node => {
    if (isLoadingMore) return;
    if (observer2.current) observer2.current.disconnect();
    observer2.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasLess && !showConfiguration && !showDetails) {
        setTimeout(() => {
          fetchPreviousOrders();
        }, 200);
      }
    });
    if (node) observer2.current.observe(node);
  }, [isLoadingMore, hasLess, showConfiguration, showDetails]);

  const fetchHeaders = async (partType) => {
    try {
      const payload = { partType };
      const response = await fetch(`${apiUrl}/api/getHeaders`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token, 
        },
        body: JSON.stringify(payload),
        credentials: 'include',
      });
      const data = await response.json();
      setHeaders(data.headers);
      setTableHeaders(data.tableHeaders);
    } catch (error) {
      console.error('Error fetching headers', error);
    }
  };

  const fetchAdditionalOrders = () => {
    storeScrollPosition();
    const nextPage = currentPage + 1;
    fetchData(partType, nextPage, false, 'down', currentDatabaseOffset);
  };

  const fetchPreviousOrders = () => {
    storeScrollPosition();
    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      fetchData(partType, previousPage, false, 'up', currentDatabaseOffset);
    }
  }

  const fetchData = async (partType, page = currentPage, isInitialLoad = false, scrolling, currentDatabaseOffset) => {
    const isFiltering = Object.values(filterStateMap).some(value => value !== "");

    if (isInitialLoad) setIsLoadingInitial(true);
    else setIsLoadingMore(true);
    setLoading(true);

    
    const scrollDirection = scrolling

    const offset = (scrollDirection === 'up') ? (page === 1 ? offsetMap[1][0] : (offsetMap[currentPage - 1][0]) - 20) : (page === 1 ? offsetMap[1][1] : offsetMap[currentPage][1]);
    try {
      const endpoint = `${apiUrl}/api/equipment`;
      const payload = { 
        part_type: partType, 
        offset: offset,
        limit: page === 1 ? itemsPerPage : 10,
        filtering: isFiltering,
        scrollDirection: scrollDirection === 'up' ? 'up' : 'down'
      };
      Object.entries(filterStateMap).forEach(([key, value]) => {
        if (value) payload[key] = value;
      });
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token, 
         },
        body: JSON.stringify(payload),
        credentials: 'include',
      };
      const response = await fetch(endpoint, requestOptions);
      if (response.status === 400) {
        setUser(null)
        return;
      }
      const newData = await response.json();
      if (newData.dbEnd === true) {
        setDbEnd(true)
      } else {
        setDbEnd(false)
      }
      if (scrollDirection === 'up') {

      } else {
        setcurrentDatabaseOffset(newData.currentDatabaseOffset)
        setPreviousDatabaseOffset(newData.previousDatabaseOffset)
        setOffsetMap(prevMap => ({
            ...prevMap,
            [page]: [newData.previousDatabaseOffset, newData.currentDatabaseOffset, newData.table_data.length],
        }));
      }
      
      if (scrollDirection === 'up') {
        if (page === 1) {
          setData(newData.table_data);
          setHeaders(newData.table_headers);
          setDatabaseHeaders(newData.database_headers);
        } else {
          setData(prevData => [...newData.table_data, ...prevData.slice(0, -(offsetMap[currentPage][2]) )]);
          adjustScrollPositionUp();
        }
        
      } 
      if (scrollDirection === 'down') {
        if (page === 1) {
          setData(newData.table_data);
          setHeaders(newData.table_headers);
          setDatabaseHeaders(newData.database_headers); 
        } else {
          if (newData.table_data.length > 0) {
            setData(prevData => [...prevData.slice(10), ...newData.table_data]);
            adjustScrollPositionDown();
          } else {
            
          }
        } 
      } else {
        if (page === 1) {
          setData(newData.table_data);
          setHeaders(newData.table_headers);
          setDatabaseHeaders(newData.database_headers);
        }
      }
      setHasLess(page > 1)
      setHasMore(newData.table_data.length > 0);
      setCurrentPage(page);
      
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
      if (isInitialLoad) setIsLoadingInitial(false);
      else setIsLoadingMore(false);
    }
  };

  const fetchOperatingData = async (partType, classifier) => {
    if (filters === false) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const endpoint = `${apiUrl}/api/filterdata`;
        const payload = {
          part_type: partType,
          classifier: classifier,
        };
        const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'X-CSRFToken': cookies.csrf_token, 
          },
          body: JSON.stringify(payload),
          credentials: 'include',
        };
        const response = await fetch(endpoint, requestOptions);
        if (response.status === 429) {
          showAlertMessage('warning', 'Too many requests')
          return
        }

        const data = await response.json();
        setTimeout(() => {
          switch (classifier) {
            case 'operatingconditions': {
              setSampleOpDataState(data['classifierData']);
              setOperatingConditions('reset');
              setLoaded(true);
              break;
            }
            case 'measuringdata': {
              setSampleMeasuringDataState(data['classifierData']);
              setMeasuringRangeData('reset');
              setLoaded(true);
              break;
            }
            case 'connectiondata': {
              setSampleConnetionDataState(data['classifierData']);
              setConnectionData('reset');
              setLoaded(true);
              break;
            }
            // Other cases can be added here
            default:
              break;
          }
        }, 300); // 0.3 seconds delay
        setFilters(true)
      } catch (error) {
        console.error('Error fetching data', error);
      }
    } else {
      return
    }
    
  };


  return (
    <EquipmentContext.Provider
    value={{
        partTypes,
        setPartTypes,
        partType,
        setPartType,
        headers,
        tableHeaders,
        databaseHeaders,
        data,
        setData,
        isLoading,
        fetchHeaders,
        fetchData,
        lastDataElementRef,
        setHasMore,
        setCurrentPage,
        tableContainerRef,
        tableTopRef,
        targetRowRef,
        firstDataElementRef,
        targetRowRef2,
        showDetails,
        setShowDetails,
        showConfiguration,
        setShowConfiguration,
        headersRef,
        currentConfigRow,
        setCurrentConfigRow,
        isLoadingInitial,
        currentPage, 
        currentDatabaseOffset,
        setcurrentDatabaseOffset,
        companyName, setCompanyName,
        partName, setPartName,
        partNumber, setPartNumber,
        connectionData, setConnectionData,
        operatingConditions, setOperatingConditions,
        measuringRangeData, setMeasuringRangeData,
        mocData, setMocData,
        filterStateMap,
        fetchOperatingData,
        sampleOpDataState,
        sampleConnectionDataState,
        sampleMeasuringDataState,
        loaded,
        filtering,
        filterData,
        selectedButton,
        setSelectedButton,
        selectedButton2,
        setSelectedButton2,
        expandedRow,
        setExpandedRow
      }}
    >
      {children}
    </EquipmentContext.Provider>
  );
};

export const useEquipment = () => useContext(EquipmentContext);

