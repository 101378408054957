import { useState, useRef, useEffect } from 'react';
import { useWebSocket } from './../../WebSocketContext.js';
import FilterComponent from './FilterComponent.js'
import TableComponent from './TableComponent.js'
import PartTypeSelectorComponent from './PartTypeSelectorComponent.js'
import VisibilityControlPanel from './VisibilityControlPanel.js'
import './css/EquipmentComponent.css'
import useIdleActivity from '../../useIdleActivity'; // Import the custom hook
import { useScroll } from './scrollContext'
import { useAuth } from '../../AuthContext.js';
import { useEquipment } from './CombinedContext.js'; // Import the custom hook
import { useNavigate } from 'react-router-dom';

function EquipmentComponent(props) {
    
    const { stockUpdates, setStockUpdates, partUpdates, setPartUpdates } = useWebSocket();
    const [changedItems, setChangedItems] = useState({}); // Track changed items
    const { partType, setPartType, headers, tableHeaders, databaseHeaders, data, setData, isLoading, fetchData, showDetails, showConfiguration, headersRef, currentConfigRow } = useEquipment(); // Use the custom hook
    const { setUser } = useAuth()   
    const navigate = useNavigate()
    const handleLogout = () => {
        setUser(null);
        navigate('/login')
        // Optionally, you can also remove cookies or perform other logout actions here
    };
    useIdleActivity(handleLogout);

    useEffect(() => {
      // Handle stock updates
      if (stockUpdates.length > 0) {
        const parsedStockUpdates = stockUpdates.map(update => JSON.parse(update));        
        const updatedData = data.map(item => {
          const updatedItem = parsedStockUpdates.find(update => 
            update.items.some(updItem => updItem.supplierpartnumber === item.supplierpartnumber && updItem.supplier === item.supplier)
          );
          if (updatedItem) {
            const matchingUpdate = updatedItem.items.find(updItem => updItem.supplierpartnumber === item.supplierpartnumber && updItem.supplier === item.supplier);
            setChangedItems(prev => ({ ...prev, [item.supplierpartnumber]: true }));
            setTimeout(() => setChangedItems(prev => ({ ...prev, [item.supplierpartnumber]: false })), 3000);
            return { ...item, quantity: item.quantity - matchingUpdate.quantity };
          }
          return item;
        });
        setData(updatedData);
        setStockUpdates([])
      }
    }, [stockUpdates]);

    useEffect(() => {
      //handle the part quant/price updates
      if (partUpdates.length > 0) {        
        const parsedPartUpdates = partUpdates.map(update => JSON.parse(update));
        const updatedData = data.map(item => {
          const updatedItem = parsedPartUpdates.find(update => 
            update.items.some(updItem => updItem.supplierpartnumber === item.supplierpartnumber && updItem.supplier === item.supplier)
          );
          if (updatedItem) {
            const matchingUpdate = updatedItem.items.find(updItem => updItem.supplierpartnumber === item.supplierpartnumber && updItem.supplier === item.supplier);
            setChangedItems(prev => ({ ...prev, [item.supplierpartnumber]: true }));
            setTimeout(() => setChangedItems(prev => ({ ...prev, [item.supplierpartnumber]: false })), 3000);
            return { ...item, quantity: matchingUpdate.quantity, price: matchingUpdate.price };
          }
          return item;
        });
  
        setData(updatedData);
        setPartUpdates([])
      }
    },[partUpdates])

    const equipmentRef = useRef(null);
    const [,setEquipmentHeight] = useState(null);
    const [tableWidth, setTableWidth] = useState([])
    const [scrollBarMarginLeft, setScrollBarMarginLeft] = useState([])
    const [scrollBarMarginRight, setScrollBarMarginRight] = useState([])
    const [showScrollBar, setShowScrollBar] = useState(true)
    const [isPartTypeVisible, setIsPartTypeVisible] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const togglePartTypeSelector = () => {
        setIsPartTypeVisible(!isPartTypeVisible)
        if (isFilterVisible === true) {
            setIsFilterVisible(!isFilterVisible)
        }
    };

    const toggleFilterComponent = () => {
        setIsFilterVisible(!isFilterVisible)
        if (isPartTypeVisible === true) {
            setIsPartTypeVisible(!isPartTypeVisible)
        }
    };

    const { setScrollPosition } = useScroll(); // Use setScrollPosition from context
    const handleScroll = (event) => {
        setScrollPosition(event.target.scrollLeft);
    };

    useEffect(() => {
        if (props.refreshData) {
          fetchData(partType);
          props.newRefreshData(false);
        }
      }, [props.refreshData]);
    
    useEffect(() => {
        if (equipmentRef.current) {
          const initialHeight = equipmentRef.current.offsetHeight ;
          setEquipmentHeight(initialHeight);
        }
      }, []);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const normalDatabaseHeaders = databaseHeaders.filter(header => ['supplierpartnumber', 'partname', 'supplier', 'price', 'quantity', 'weight'
    ]
    .includes(header))

    const showOperatingConditions = databaseHeaders.includes('operatingconditions')
    const showProcessConnections = databaseHeaders.some(header => normalDatabaseHeaders.includes(header));
    const showElectricalData = databaseHeaders.some(header => normalDatabaseHeaders.includes(header))
    const showMeasuringData = databaseHeaders.some(header => normalDatabaseHeaders.includes(header))

    useEffect(() => {
      setShowScrollBar(true)
    },[partType])

    return (
        <div ref={equipmentRef} className="equipment-component" style= {{height: `calc(${windowHeight}px - 63px)`, width: "100%", boxSizing: "border-box", marginRight: "10px" }}>
            <div>
                <VisibilityControlPanel
                    togglePartTypeSelector={togglePartTypeSelector}
                    toggleFilterComponent={toggleFilterComponent}
                    isPartTypeVisible={isPartTypeVisible}
                    isFilterVisible={isFilterVisible}
                />
                {isPartTypeVisible && <PartTypeSelectorComponent setPartType={setPartType} />}
                {isFilterVisible && <FilterComponent filters={databaseHeaders} setData={setData} partType={partType} data={data} />}
            </div>
            <div className="table-headers" style={{display: 'flex'}}>
                <div className="table-fixed">
                    <table>
                      <thead>
                        <tr>
                            <th style={{minWidth: '400px'}}>Part Details</th>
                        </tr>
                      </thead>
                    </table>
                </div>
                <div className="table-fixed2">
                  <table>
                    <thead>
                      <tr>
                        <th>Cart Details</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {!showDetails && !showConfiguration && (
                <div className="table-overflow" style={{overflowX: 'hidden', marginRight: '18px'}} ref={headersRef}>
                  <table>
                    <thead>
                        <tr>
                          {showOperatingConditions && <th style={{minWidth: '235px'}}>Operating Conditions</th>}
                          {showMeasuringData && <th style={{minWidth: '235px'}}>Measuring Range</th>}
                          {showProcessConnections && <th style={{minWidth: '235px'}}>Process Connections</th>}
                          {showElectricalData && <th style={{minWidth: '235px'}}>Electrical Data</th>}              
                        </tr>
                    </thead>
                  </table>
                </div>
                )}
                {showDetails && !showConfiguration && (
                <div style={{width: "100%", overflowY: "auto", marginRight: '18px'}} className="more-details-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Additional Details</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                )}
                {showConfiguration && !showDetails && currentConfigRow && (
                <div className="more-details-table" style={{marginRight: '18px'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>Part Configuration</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                )}
            </div>
            <div className="scrollable-div" style={{ overflowY: 'auto', overflowX: 'hidden', width: "100%", height: "100%",boxSizing: "border-box", display: "flex", "flexDirection": "column"}}>
                <TableComponent
                    partType={partType}
                    filters={headers}
                    data={data}
                    tableHeaders={tableHeaders}
                    databaseHeaders={databaseHeaders}
                    isLoading={isLoading}
                    setTableWidth={setTableWidth}
                    setTable1VisibleWidth={setScrollBarMarginLeft}
                    setTable3VisibleWidth={setScrollBarMarginRight}
                    setShowScrollBar={setShowScrollBar}
                    isPartTypeVisible={isPartTypeVisible}
                    isFilterVisible={isFilterVisible}
                    changedItems={changedItems}
                />
                {showScrollBar && (
                  <div className="dummy-scrollbar" onScroll={handleScroll} style={{marginLeft: `${scrollBarMarginLeft + scrollBarMarginRight - 25}px`, marginRight: '18px', overflowX: 'scroll', position: 'relative', flex: '0 0 auto'}}>
                      <div style={{height: '1px', width: `${tableWidth}%` }}></div>
                  </div>
                )}
                
            </div>
        </div>
    );
}

export default EquipmentComponent;


