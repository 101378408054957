import { useEffect } from 'react';

const useIdleActivity = (handleLogout, SESSION_TIMEOUT = 30 * 60 * 1000) => {
  useEffect(() => {
    const handleUserActivity = () => {
      const now = new Date().getTime();
      localStorage.setItem('lastActivity', now);
    };

    const checkActivity = () => {
      const now = new Date().getTime();
      const lastActivity = localStorage.getItem('lastActivity');
      if (now - lastActivity > SESSION_TIMEOUT) {
        handleLogout(); // Call logout function
      }
    };

    // Set initial activity time
    handleUserActivity();
    // Set interval to check for activity
    const interval = setInterval(checkActivity, 10000); // check every 10 seconds
    // Event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    // Cleanup on unmount
    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
    };
  }, [handleLogout, SESSION_TIMEOUT]);
};

export default useIdleActivity;
