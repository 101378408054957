import React, { useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import './css/OrderCompletedComponent.css'; // Make sure the path is correct
import '@stripe/stripe-js';
import { useAuth } from '../AuthContext';
import { BreadCrumbContext } from "./context/BreadCrumbContext";
import { useFormData } from './context/FormDataContext.js';
import { useOrders } from './../orders/OrdersContext';

function OrderCompletedComponent() {
    const { user, setUser } = useAuth();
    const { orderNumber } = useContext(BreadCrumbContext);
    const {formData, formData3 } = useFormData()
    
    const { reloadOrders } = useOrders();
    

    // run fetch orders from the orders context again




    // Function to update user state with new previous_delivery and previous_billing
    const updateUserDetails = (newDelivery, newBilling) => {
        setUser(prevUser => ({
            ...prevUser,
            previous_delivery: newDelivery,
            previous_billing: newBilling,
        }));
    };

    useEffect(() => {
        // Example usage: Updating user details after order completion
        const newDelivery = {
            address: {
                city: formData.locality,
                country: formData.country,
                line1: formData.location,
                line2: '',
                postal_code: formData.postal_code,
                state: formData.administrative_area_level_1
            },
            geolocation: {
                latitude: formData.latitude,
                longitude: formData.longitude
            }
        };

        const newBilling = {
            address: {
                city: formData3.locality,
                country: formData3.country,
                line1: formData3.location,
                line2: '',
                postal_code: formData3.postal_code,
                state: formData3.administrative_area_level_1
            },
            geolocation: {
                latitude: formData3.latitude,
                longitude: formData3.longitude
            }
        };

        updateUserDetails(newDelivery, newBilling);
        reloadOrders()
    }, []); // Empty dependency array to run only once after component mounts

    return (
        <div className="thank-you-container" style={{"alignItems": "center"}}>
            <h1>Thank You for Your Order!</h1>
            <p>Your order number is <strong>{orderNumber}</strong>.</p>
            <p>A confirmation email has been sent to <strong>{user.email}</strong>.</p>
        </div>
    );
}

export default OrderCompletedComponent;
