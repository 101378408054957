import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faGear } from '@fortawesome/free-solid-svg-icons';
import './css/VisibilityControlPanel.css';

const VisibilityControlPanel = ({ isPartTypeVisible, togglePartTypeSelector, isFilterVisible, toggleFilterComponent }) => {
    return (
        <div className="visibility-control-panel">
            <button 
                onClick={togglePartTypeSelector} 
                className={isPartTypeVisible ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faGear} />
                {" Part Type"}
            </button>
            <button 
                onClick={toggleFilterComponent} 
                className={isFilterVisible ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faFilter} />
                {" Part Filters"}
            </button>
        </div>
    );
}

export default VisibilityControlPanel;
