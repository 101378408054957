import React from 'react';

function ProcessConnections({ connections }) {
    if (connections && typeof connections === 'object' && connections.hasOwnProperty('connection1')) {
        const connectionFields = ['type', 'size', 'units'];

        // Function to format the connection data
        const formatConnection = (connection) => {
            const formattedFields = connectionFields.map(field => {
                if (field === 'size' && Array.isArray(connection[field])) {
                    const sizes = connection[field];
                    if (sizes.length > 1) {
                        // Display as min(sizes) - max(sizes)
                        const sizeRange = `${Math.min(...sizes)} - ${Math.max(...sizes)}`;
                        return sizeRange;
                    } else if (sizes.length === 1) {
                        // Display single size
                        return sizes[0];
                    } else {
                        return ''; // No sizes to display
                    }
                }
                return connection[field];
            }).filter(val => val).join(' '); // Filter out null or undefined values and join the non-empty fields

            return formattedFields;
        };

        // Collect all connections in an array
        const connectionElements = [];

        // Process connection1 as it's guaranteed to be present
        connectionElements.push(
            <div key="connection1" style={{ display: 'flex' }}>
                <span style={{ marginRight: '5px', width: '90px', textAlign: 'left' }}>Connection 1:</span>
                <span>{formatConnection(connections.connection1)}</span>
            </div>
        );

        // Check and format optional connections
        ['connection2', 'connection3', 'connection4'].forEach((conn, index) => {
            if (connections.hasOwnProperty(conn) && connections[conn].type !== null) {
                connectionElements.push(
                    <div key={conn} style={{ display: 'flex' }}>
                        <span style={{ marginRight: '5px', width: '90px', textAlign: 'left' }}>Connection {index + 2}:</span>
                        <span>{formatConnection(connections[conn])}</span>
                    </div>
                );
            }
        });

        // Return the array of connection elements
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {connectionElements}
                </div>
            </div>
        );
    }

    // If connections do not match the expected format, return null
    return null;
}

export default ProcessConnections;
