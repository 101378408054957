import React, { useEffect } from 'react'
import { useFormData } from './context/FormDataContext.js'
import { useGoogleMaps } from './hook/useGoogleMaps.js'
import { useAuth } from '../AuthContext.js'
import { useShipmentData } from './context/ShipmentDataContext.js'

import InputBoxComponent from '../InputBoxComponent.tsx';


function ShippingInformationComponent() {
    const {user} = useAuth()
    const {formData, updateFormData, handleUseAddressClick} = useFormData()
    const {shippingData, updateShippingData} = useShipmentData()
    //put api key on backend
    const { initAutocomplete, isLoaded } = useGoogleMaps('AIzaSyBKrYgDlQdiuin7l4VFs5WEU6nz0mRQywc', {
        fields: ['address_components', 'geometry', 'name'],
        types: ['address'],
        componentRestrictions: { country: ['AU'] }
    });

    useEffect(() => {
        if (isLoaded && document.getElementById('Address Line 1')) {
            initAutocomplete('delivery-details', 'Address Line 1', 
            {
                fields: ['address_components', 'geometry', 'name'],
                types: ['address'],
                componentRestrictions: { country: ['AU'] }
            }, place => {}
        )};
    }, [initAutocomplete, isLoaded]);

    

    return (
        <div className="delivery-details" style={{"width": "100%", "marginRight":"10px"}}>
            <h2>Shipping</h2>
            
            {user && user.previous_delivery !== null && (
                <div className="form-group">
                    <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                        <div className="form-group location-group-confirm" style={{"width":"100%"}}>
                            <div className="form-group-wrapper-confirm">
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <input 
                                        style={{ backgroundColor: "#f0f0f0"}} 
                                        type="text" 
                                        id="Delivery Address Details" 
                                        placeholder=" " 
                                        defaultValue={`${user.previous_delivery.address.line2} ${user.previous_delivery.address.line1} ${user.previous_delivery.address.city}, ${user.previous_delivery.address.state} ${user.previous_delivery.address.postal_code}, ${user.previous_delivery.address.country}`}
                                        disabled>
                                    </input>
                                    <label htmlFor="email">Previous Delivery Address</label>
                                    <button onClick={() => handleUseAddressClick()} className="change-btn">Use Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="location-container" style={{"display":"flex", "gap": "10px"}}>
                <div className="form-group-wrapper">
                    <div className="form-group location-group" style={{"minWidth": "100%"}}>
                        <InputBoxComponent
                          string={formData.given_name}
                          setString={(e) => {updateFormData({ ...formData, given_name: e })}}
                          label={'First Name'}
                          regexType='string'
                        />
                    </div>
                </div>
                <div className="form-group-wrapper">
                    <div className="form-group location-group" style={{"minWidth": "100%"}}>
                        <InputBoxComponent
                          string={formData.family_name}
                          setString={(e) => {updateFormData({ ...formData, family_name: e })}}
                          label={'Last Name'}
                          regexType='string'
                        />
                    </div>
                </div>   
            </div>
            <InputBoxComponent
              string={formData.company}
              setString={(e) => {updateFormData({ ...formData, company: e })}}
              label={'Company'}
              regexType='string'
              
            />
            <InputBoxComponent
              string={formData.abn}
              setString={(e) => {updateFormData({ ...formData, abn: e })}}
              label={'ABN'}
              regexType='number'
            />
            <InputBoxComponent
              string={formData.location}
              setString={(e) => {updateFormData({ ...formData, location: e })}}
              label={'Address Line 1'}
              regexType='address'
            />
            <InputBoxComponent
              string={formData.suite}
              setString={(e) => {updateFormData({ ...formData, suite: e })}}
              label={'Address Line 2'}
              regexType='address'
            />
            <div className="location-container" style={{"display":"flex", "gap": "10px", minWidth: '100%'}}>
                <div style={{width: "100%"}}>
                    <InputBoxComponent
                      string={formData.locality}
                      setString={(e) => {updateFormData({ ...formData, locality: e })}}
                      label={'City'}
                      regexType='string'
                    />
                </div>
                <div style={{width: "100%"}}>
                <InputBoxComponent
                  string={formData.administrative_area_level_1}
                  setString={(e) => {updateFormData({ ...formData, administrative_area_level_1: e })}}
                  label={'State'}
                  regexType='string'
                />
                </div>
                <div style={{width: "100%"}}>
                <InputBoxComponent
                  string={formData.postal_code}
                  setString={(e) => {updateFormData({ ...formData, postal_code: e })}}
                  label={'Postal Code'}
                  regexType='number'
                />
                </div>
            </div>
            <InputBoxComponent
              string={formData.country}
              setString={(e) => {updateFormData({ ...formData, country: e })}}
              label={'Country'}
              regexType='string'
            />
            <div style={{"display":"flex", "gap": "10px", minWidth: '100%'}}>
                <div style={{width: '120px'}}>
                                            
                    <InputBoxComponent
                      string={'+61'}
                      defaultValue={'+61'}
                      setString={''}
                      disabled={true}
                      label={'Country Code'}
                      regexType='string'
                    />
                </div>
                <div style={{width: '100%'}}>
                <InputBoxComponent
                  string={formData.contact_number}
                  setString={(e) => {updateFormData({ ...formData, contact_number: e })}}
                  label={'Contact Number'}
                  regexType='number'
                />
                </div>
                
            </div>
            
            <h2>Shipping Method</h2>
            <div><p>Choose between express or standard shipping</p></div>
            <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc' }}>
                <div style={{borderBottom: "solid 1px #ccc", padding: "10px 8px"}}>
                    <input
                        id="express-shipping"
                        type="radio"
                        name="deliverymethod"
                        className='hidden-radio'
                        value="Express shipping"
                        checked={shippingData.delivery_method === "Express shipping"}
                        onChange={() => updateShippingData({...shippingData, delivery_method: "Express shipping" })}
                    />
                    <label htmlFor="express-shipping" className="input-radio-label">
                        <span className="input-radio"></span>
                        Express Shipping (1-2 Days)
                    </label>
                </div>
                <div style={{padding: "10px 8px"}}>
                    <input
                        id="standard-shipping"
                        type="radio"
                        name="deliverymethod"
                        className="hidden-radio"
                        value="Standard shipping"
                        checked={shippingData.delivery_method === "Standard shipping"}
                        onChange={() => updateShippingData({...shippingData, delivery_method: "Standard shipping"})}
                    />
                    <label htmlFor="standard-shipping" className="input-radio-label">
                        <span className="input-radio"></span>
                        Standard Shipping (3-4 Days)
                    </label>
                    
                </div>
            </div>
            <h2>Shipping Type</h2>
            <div><p>Choose between whether all parcels from each supplier will be delivered as whole orders, or parcels will arrive as they are prepared</p></div>
            <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc'}}>
                <div style={{borderBottom: "solid 1px #ccc", padding: "10px 8px"}}>
                    <input
                        id="single-shipments"
                        type="radio"
                        name="shippingtype"
                        className='hidden-radio'
                        value="Single shipments"
                        checked={shippingData.shipping_type === "Single shipments"}
                        onChange={() => updateShippingData({...shippingData, shipping_type: "Single shipments"})}
                    />
                    <label htmlFor="single-shipments" className="input-radio-label">
                        <span className="input-radio"></span>
                        Delivered as one shipment
                    </label>
                </div>
                <div style={{padding: "10px 8px"}}>
                    <input
                        id="delivered-as-available"
                        type="radio"
                        name="shippingtype"
                        className="hidden-radio"
                        value="Delivered as available"
                        checked={shippingData.shipping_type === "Delivered as available"}
                        onChange={() => updateShippingData({...shippingData, shipping_type: "Delivered as available"})}
                    />
                    <label htmlFor="delivered-as-available" className="input-radio-label">
                        <span className="input-radio"></span>
                        Delivered as items are available
                    </label>
                </div>
                
            </div>
            
            <input id="billing-location-input" className="visually-hidden" disabled></input>
            
            
            
            
        </div>
    )
}

export default ShippingInformationComponent