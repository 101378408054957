import React from 'react';

function MeasuringData({ inputArray }) {
    // Function to render a single measurement
    const renderMeasurement = (data) => {
        if (data && typeof data === 'object' && 
            data.hasOwnProperty('min') && 
            data.hasOwnProperty('max') && 
            data.hasOwnProperty('units')) {
            
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{width: '100%', textAlign: 'center' }}>
                            <span>{`${data.min} - ${data.max} ${data.units}`}</span>
                            {data.accuracy && (
                                <span> ±{`${data.accuracy}`}%</span>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        // If data does not match the expected format,
        // Check if it's a simple type (string or number) and return it directly,
        // or return null if it's none of those.
        return typeof data === 'string' || typeof data === 'number' 
            ? <span>{data}</span> 
            : null;
    };

    // Check if inputArray is null or not an object
    if (!inputArray || typeof inputArray !== 'object') {
        return null;
    }

    // Iterate over the keys in the inputArray and render the measurements
    return (
        <div>
            {Object.keys(inputArray).map((key) => (
                <div key={key}>
                    {renderMeasurement(inputArray[key])}
                </div>
            ))}
        </div>
    );
}

export default MeasuringData;
