import React, { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAuth } from './../../AuthContext.js'

const CheckoutContext = createContext();

function CheckoutProvider({ children }) {
    const { setUser } = useAuth();
    const [transactionId] = useState(null);
    const [cookies] = useCookies(['csrf_token']); 
    const apiUrl = process.env.REACT_APP_API_URL;

    const initiateCheckout = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/initiate-checkout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token, 
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (response.status === 400) {
                setUser(null)
                return;
              }
        } catch (error) {
            console.error('Error initiating checkout:', error);
        }
    };

    return (
        <CheckoutContext.Provider value={{transactionId, initiateCheckout}}>
            {children}
        </CheckoutContext.Provider>
    );
}

export { CheckoutContext, CheckoutProvider };