import React, { useRef, useLayoutEffect, useState } from 'react';
import { TooltipModal } from '@zendeskgarden/react-modals';
import { Grid } from '@zendeskgarden/react-grid';
import { ThemeProvider, DEFAULT_THEME } from '@zendeskgarden/react-theming';
import styled from 'styled-components';

const Container = styled.div`
    max-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
    
`;

function MocData({ inputArray }) {
    const divRef = useRef(null);
    const buttonRef = useRef(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const [, setIsTruncated] = useState(false);
    
    const checkOverflow = () => {
        if (!Array.isArray(inputArray)) {
            setIsTruncated(false);
            return;
        }

        const overflow = inputArray.length > 4 || inputArray.some(item => {
            const span = document.createElement('div');
            span.style.whiteSpace = 'nowrap';
            span.style.visibility = 'hidden';
            span.innerText = item;
            document.body.appendChild(span);
            const isOverflowing = span.offsetWidth > divRef.current.clientWidth;
            document.body.removeChild(span);
            return isOverflowing;
        });

        setIsTruncated(overflow);
    };

    useLayoutEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [inputArray]);

    if (!Array.isArray(inputArray)) {
        return null; // or any fallback UI you want to render for null or non-array inputArray
    }

    return (
        <ThemeProvider theme={DEFAULT_THEME}>
            <Grid.Row>
                <Grid.Col textAlign="center">
                    <Container ref={divRef}>
                        
                            <button
                                ref={buttonRef}
                                style={{ }}
                                onClick={() => {
                                    setReferenceElement(buttonRef.current);
                                }}
                                className={`part-type-button`}
                            >
                                Materials
                            </button>
                       
                    </Container>
                    {referenceElement && (
                        <TooltipModal
                            referenceElement={referenceElement}
                            onClose={() => setReferenceElement(null)}
                            placement="bottom-start"
                            aria-label="Modal dialog"
                        >
                            <TooltipModal.Title tag="h2">Materials of Construction</TooltipModal.Title>
                            <TooltipModal.Body>
                                {inputArray.length > 1 && (
                                    <div>
                                        List of materials to choose from:<br/>
                                    </div>
                                )}
                                {inputArray.map((item, index) => (
                                    <div key={index}>
                                        {index + 1}{". "}{item}
                                    </div>
                                ))}
                            </TooltipModal.Body>
                            <TooltipModal.Close aria-label="Close" />
                        </TooltipModal>
                    )}
                </Grid.Col>
            </Grid.Row>
        </ThemeProvider>
    );
}

export default MocData;

//
//
//

//{expandedItems[index] ? (
//    <div>{item} <button onClick={() => toggleTruncated(index)}>Less</button></div>
//) : (
//    <LinesEllipsis
//        text={item}
//        maxLine='1'
//        ellipsis={<>... <button onClick={() => toggleTruncated(index)}>Read More</button></>}
//        basedOn='letters'
//    />
//)}


//{inputArray.map((item, index) => (
                        //            <div key={index}>
                        //                {item}
                        //            </div>
                        //        ))}
                        //{isTruncated && (


                         //)}